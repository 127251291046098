@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Arimo:400,700);
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }
  .container:before, .container:after {
    content: " ";
    display: table; }
  .container:after {
    clear: both; }
  @media (min-width: 768px) {
    .container {
      width: 750px; } }
  @media (min-width: 992px) {
    .container {
      width: 970px; } }
  @media (min-width: 1200px) {
    .container {
      width: 1170px; } }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }
  .container-fluid:before, .container-fluid:after {
    content: " ";
    display: table; }
  .container-fluid:after {
    clear: both; }

.row {
  margin-left: -15px;
  margin-right: -15px; }
  .row:before, .row:after {
    content: " ";
    display: table; }
  .row:after {
    clear: both; }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12, .col-xs-13, .col-sm-13, .col-md-13, .col-lg-13, .col-xs-14, .col-sm-14, .col-md-14, .col-lg-14, .col-xs-15, .col-sm-15, .col-md-15, .col-lg-15, .col-xs-16, .col-sm-16, .col-md-16, .col-lg-16, .col-xs-17, .col-sm-17, .col-md-17, .col-lg-17, .col-xs-18, .col-sm-18, .col-md-18, .col-lg-18, .col-xs-19, .col-sm-19, .col-md-19, .col-lg-19, .col-xs-20, .col-sm-20, .col-md-20, .col-lg-20, .col-xs-21, .col-sm-21, .col-md-21, .col-lg-21, .col-xs-22, .col-sm-22, .col-md-22, .col-lg-22, .col-xs-23, .col-sm-23, .col-md-23, .col-lg-23, .col-xs-24, .col-sm-24, .col-md-24, .col-lg-24 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-13, .col-xs-14, .col-xs-15, .col-xs-16, .col-xs-17, .col-xs-18, .col-xs-19, .col-xs-20, .col-xs-21, .col-xs-22, .col-xs-23, .col-xs-24 {
  float: left; }

.col-xs-1 {
  width: 4.16667%; }

.col-xs-2 {
  width: 8.33333%; }

.col-xs-3 {
  width: 12.5%; }

.col-xs-4 {
  width: 16.66667%; }

.col-xs-5 {
  width: 20.83333%; }

.col-xs-6 {
  width: 25%; }

.col-xs-7 {
  width: 29.16667%; }

.col-xs-8 {
  width: 33.33333%; }

.col-xs-9 {
  width: 37.5%; }

.col-xs-10 {
  width: 41.66667%; }

.col-xs-11 {
  width: 45.83333%; }

.col-xs-12 {
  width: 50%; }

.col-xs-13 {
  width: 54.16667%; }

.col-xs-14 {
  width: 58.33333%; }

.col-xs-15 {
  width: 62.5%; }

.col-xs-16 {
  width: 66.66667%; }

.col-xs-17 {
  width: 70.83333%; }

.col-xs-18 {
  width: 75%; }

.col-xs-19 {
  width: 79.16667%; }

.col-xs-20 {
  width: 83.33333%; }

.col-xs-21 {
  width: 87.5%; }

.col-xs-22 {
  width: 91.66667%; }

.col-xs-23 {
  width: 95.83333%; }

.col-xs-24 {
  width: 100%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-pull-1 {
  right: 4.16667%; }

.col-xs-pull-2 {
  right: 8.33333%; }

.col-xs-pull-3 {
  right: 12.5%; }

.col-xs-pull-4 {
  right: 16.66667%; }

.col-xs-pull-5 {
  right: 20.83333%; }

.col-xs-pull-6 {
  right: 25%; }

.col-xs-pull-7 {
  right: 29.16667%; }

.col-xs-pull-8 {
  right: 33.33333%; }

.col-xs-pull-9 {
  right: 37.5%; }

.col-xs-pull-10 {
  right: 41.66667%; }

.col-xs-pull-11 {
  right: 45.83333%; }

.col-xs-pull-12 {
  right: 50%; }

.col-xs-pull-13 {
  right: 54.16667%; }

.col-xs-pull-14 {
  right: 58.33333%; }

.col-xs-pull-15 {
  right: 62.5%; }

.col-xs-pull-16 {
  right: 66.66667%; }

.col-xs-pull-17 {
  right: 70.83333%; }

.col-xs-pull-18 {
  right: 75%; }

.col-xs-pull-19 {
  right: 79.16667%; }

.col-xs-pull-20 {
  right: 83.33333%; }

.col-xs-pull-21 {
  right: 87.5%; }

.col-xs-pull-22 {
  right: 91.66667%; }

.col-xs-pull-23 {
  right: 95.83333%; }

.col-xs-pull-24 {
  right: 100%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-push-1 {
  left: 4.16667%; }

.col-xs-push-2 {
  left: 8.33333%; }

.col-xs-push-3 {
  left: 12.5%; }

.col-xs-push-4 {
  left: 16.66667%; }

.col-xs-push-5 {
  left: 20.83333%; }

.col-xs-push-6 {
  left: 25%; }

.col-xs-push-7 {
  left: 29.16667%; }

.col-xs-push-8 {
  left: 33.33333%; }

.col-xs-push-9 {
  left: 37.5%; }

.col-xs-push-10 {
  left: 41.66667%; }

.col-xs-push-11 {
  left: 45.83333%; }

.col-xs-push-12 {
  left: 50%; }

.col-xs-push-13 {
  left: 54.16667%; }

.col-xs-push-14 {
  left: 58.33333%; }

.col-xs-push-15 {
  left: 62.5%; }

.col-xs-push-16 {
  left: 66.66667%; }

.col-xs-push-17 {
  left: 70.83333%; }

.col-xs-push-18 {
  left: 75%; }

.col-xs-push-19 {
  left: 79.16667%; }

.col-xs-push-20 {
  left: 83.33333%; }

.col-xs-push-21 {
  left: 87.5%; }

.col-xs-push-22 {
  left: 91.66667%; }

.col-xs-push-23 {
  left: 95.83333%; }

.col-xs-push-24 {
  left: 100%; }

.col-xs-offset-0 {
  margin-left: 0%; }

.col-xs-offset-1 {
  margin-left: 4.16667%; }

.col-xs-offset-2 {
  margin-left: 8.33333%; }

.col-xs-offset-3 {
  margin-left: 12.5%; }

.col-xs-offset-4 {
  margin-left: 16.66667%; }

.col-xs-offset-5 {
  margin-left: 20.83333%; }

.col-xs-offset-6 {
  margin-left: 25%; }

.col-xs-offset-7 {
  margin-left: 29.16667%; }

.col-xs-offset-8 {
  margin-left: 33.33333%; }

.col-xs-offset-9 {
  margin-left: 37.5%; }

.col-xs-offset-10 {
  margin-left: 41.66667%; }

.col-xs-offset-11 {
  margin-left: 45.83333%; }

.col-xs-offset-12 {
  margin-left: 50%; }

.col-xs-offset-13 {
  margin-left: 54.16667%; }

.col-xs-offset-14 {
  margin-left: 58.33333%; }

.col-xs-offset-15 {
  margin-left: 62.5%; }

.col-xs-offset-16 {
  margin-left: 66.66667%; }

.col-xs-offset-17 {
  margin-left: 70.83333%; }

.col-xs-offset-18 {
  margin-left: 75%; }

.col-xs-offset-19 {
  margin-left: 79.16667%; }

.col-xs-offset-20 {
  margin-left: 83.33333%; }

.col-xs-offset-21 {
  margin-left: 87.5%; }

.col-xs-offset-22 {
  margin-left: 91.66667%; }

.col-xs-offset-23 {
  margin-left: 95.83333%; }

.col-xs-offset-24 {
  margin-left: 100%; }

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24 {
    float: left; }
  .col-sm-1 {
    width: 4.16667%; }
  .col-sm-2 {
    width: 8.33333%; }
  .col-sm-3 {
    width: 12.5%; }
  .col-sm-4 {
    width: 16.66667%; }
  .col-sm-5 {
    width: 20.83333%; }
  .col-sm-6 {
    width: 25%; }
  .col-sm-7 {
    width: 29.16667%; }
  .col-sm-8 {
    width: 33.33333%; }
  .col-sm-9 {
    width: 37.5%; }
  .col-sm-10 {
    width: 41.66667%; }
  .col-sm-11 {
    width: 45.83333%; }
  .col-sm-12 {
    width: 50%; }
  .col-sm-13 {
    width: 54.16667%; }
  .col-sm-14 {
    width: 58.33333%; }
  .col-sm-15 {
    width: 62.5%; }
  .col-sm-16 {
    width: 66.66667%; }
  .col-sm-17 {
    width: 70.83333%; }
  .col-sm-18 {
    width: 75%; }
  .col-sm-19 {
    width: 79.16667%; }
  .col-sm-20 {
    width: 83.33333%; }
  .col-sm-21 {
    width: 87.5%; }
  .col-sm-22 {
    width: 91.66667%; }
  .col-sm-23 {
    width: 95.83333%; }
  .col-sm-24 {
    width: 100%; }
  .col-sm-pull-0 {
    right: auto; }
  .col-sm-pull-1 {
    right: 4.16667%; }
  .col-sm-pull-2 {
    right: 8.33333%; }
  .col-sm-pull-3 {
    right: 12.5%; }
  .col-sm-pull-4 {
    right: 16.66667%; }
  .col-sm-pull-5 {
    right: 20.83333%; }
  .col-sm-pull-6 {
    right: 25%; }
  .col-sm-pull-7 {
    right: 29.16667%; }
  .col-sm-pull-8 {
    right: 33.33333%; }
  .col-sm-pull-9 {
    right: 37.5%; }
  .col-sm-pull-10 {
    right: 41.66667%; }
  .col-sm-pull-11 {
    right: 45.83333%; }
  .col-sm-pull-12 {
    right: 50%; }
  .col-sm-pull-13 {
    right: 54.16667%; }
  .col-sm-pull-14 {
    right: 58.33333%; }
  .col-sm-pull-15 {
    right: 62.5%; }
  .col-sm-pull-16 {
    right: 66.66667%; }
  .col-sm-pull-17 {
    right: 70.83333%; }
  .col-sm-pull-18 {
    right: 75%; }
  .col-sm-pull-19 {
    right: 79.16667%; }
  .col-sm-pull-20 {
    right: 83.33333%; }
  .col-sm-pull-21 {
    right: 87.5%; }
  .col-sm-pull-22 {
    right: 91.66667%; }
  .col-sm-pull-23 {
    right: 95.83333%; }
  .col-sm-pull-24 {
    right: 100%; }
  .col-sm-push-0 {
    left: auto; }
  .col-sm-push-1 {
    left: 4.16667%; }
  .col-sm-push-2 {
    left: 8.33333%; }
  .col-sm-push-3 {
    left: 12.5%; }
  .col-sm-push-4 {
    left: 16.66667%; }
  .col-sm-push-5 {
    left: 20.83333%; }
  .col-sm-push-6 {
    left: 25%; }
  .col-sm-push-7 {
    left: 29.16667%; }
  .col-sm-push-8 {
    left: 33.33333%; }
  .col-sm-push-9 {
    left: 37.5%; }
  .col-sm-push-10 {
    left: 41.66667%; }
  .col-sm-push-11 {
    left: 45.83333%; }
  .col-sm-push-12 {
    left: 50%; }
  .col-sm-push-13 {
    left: 54.16667%; }
  .col-sm-push-14 {
    left: 58.33333%; }
  .col-sm-push-15 {
    left: 62.5%; }
  .col-sm-push-16 {
    left: 66.66667%; }
  .col-sm-push-17 {
    left: 70.83333%; }
  .col-sm-push-18 {
    left: 75%; }
  .col-sm-push-19 {
    left: 79.16667%; }
  .col-sm-push-20 {
    left: 83.33333%; }
  .col-sm-push-21 {
    left: 87.5%; }
  .col-sm-push-22 {
    left: 91.66667%; }
  .col-sm-push-23 {
    left: 95.83333%; }
  .col-sm-push-24 {
    left: 100%; }
  .col-sm-offset-0 {
    margin-left: 0%; }
  .col-sm-offset-1 {
    margin-left: 4.16667%; }
  .col-sm-offset-2 {
    margin-left: 8.33333%; }
  .col-sm-offset-3 {
    margin-left: 12.5%; }
  .col-sm-offset-4 {
    margin-left: 16.66667%; }
  .col-sm-offset-5 {
    margin-left: 20.83333%; }
  .col-sm-offset-6 {
    margin-left: 25%; }
  .col-sm-offset-7 {
    margin-left: 29.16667%; }
  .col-sm-offset-8 {
    margin-left: 33.33333%; }
  .col-sm-offset-9 {
    margin-left: 37.5%; }
  .col-sm-offset-10 {
    margin-left: 41.66667%; }
  .col-sm-offset-11 {
    margin-left: 45.83333%; }
  .col-sm-offset-12 {
    margin-left: 50%; }
  .col-sm-offset-13 {
    margin-left: 54.16667%; }
  .col-sm-offset-14 {
    margin-left: 58.33333%; }
  .col-sm-offset-15 {
    margin-left: 62.5%; }
  .col-sm-offset-16 {
    margin-left: 66.66667%; }
  .col-sm-offset-17 {
    margin-left: 70.83333%; }
  .col-sm-offset-18 {
    margin-left: 75%; }
  .col-sm-offset-19 {
    margin-left: 79.16667%; }
  .col-sm-offset-20 {
    margin-left: 83.33333%; }
  .col-sm-offset-21 {
    margin-left: 87.5%; }
  .col-sm-offset-22 {
    margin-left: 91.66667%; }
  .col-sm-offset-23 {
    margin-left: 95.83333%; }
  .col-sm-offset-24 {
    margin-left: 100%; } }

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24 {
    float: left; }
  .col-md-1 {
    width: 4.16667%; }
  .col-md-2 {
    width: 8.33333%; }
  .col-md-3 {
    width: 12.5%; }
  .col-md-4 {
    width: 16.66667%; }
  .col-md-5 {
    width: 20.83333%; }
  .col-md-6 {
    width: 25%; }
  .col-md-7 {
    width: 29.16667%; }
  .col-md-8 {
    width: 33.33333%; }
  .col-md-9 {
    width: 37.5%; }
  .col-md-10 {
    width: 41.66667%; }
  .col-md-11 {
    width: 45.83333%; }
  .col-md-12 {
    width: 50%; }
  .col-md-13 {
    width: 54.16667%; }
  .col-md-14 {
    width: 58.33333%; }
  .col-md-15 {
    width: 62.5%; }
  .col-md-16 {
    width: 66.66667%; }
  .col-md-17 {
    width: 70.83333%; }
  .col-md-18 {
    width: 75%; }
  .col-md-19 {
    width: 79.16667%; }
  .col-md-20 {
    width: 83.33333%; }
  .col-md-21 {
    width: 87.5%; }
  .col-md-22 {
    width: 91.66667%; }
  .col-md-23 {
    width: 95.83333%; }
  .col-md-24 {
    width: 100%; }
  .col-md-pull-0 {
    right: auto; }
  .col-md-pull-1 {
    right: 4.16667%; }
  .col-md-pull-2 {
    right: 8.33333%; }
  .col-md-pull-3 {
    right: 12.5%; }
  .col-md-pull-4 {
    right: 16.66667%; }
  .col-md-pull-5 {
    right: 20.83333%; }
  .col-md-pull-6 {
    right: 25%; }
  .col-md-pull-7 {
    right: 29.16667%; }
  .col-md-pull-8 {
    right: 33.33333%; }
  .col-md-pull-9 {
    right: 37.5%; }
  .col-md-pull-10 {
    right: 41.66667%; }
  .col-md-pull-11 {
    right: 45.83333%; }
  .col-md-pull-12 {
    right: 50%; }
  .col-md-pull-13 {
    right: 54.16667%; }
  .col-md-pull-14 {
    right: 58.33333%; }
  .col-md-pull-15 {
    right: 62.5%; }
  .col-md-pull-16 {
    right: 66.66667%; }
  .col-md-pull-17 {
    right: 70.83333%; }
  .col-md-pull-18 {
    right: 75%; }
  .col-md-pull-19 {
    right: 79.16667%; }
  .col-md-pull-20 {
    right: 83.33333%; }
  .col-md-pull-21 {
    right: 87.5%; }
  .col-md-pull-22 {
    right: 91.66667%; }
  .col-md-pull-23 {
    right: 95.83333%; }
  .col-md-pull-24 {
    right: 100%; }
  .col-md-push-0 {
    left: auto; }
  .col-md-push-1 {
    left: 4.16667%; }
  .col-md-push-2 {
    left: 8.33333%; }
  .col-md-push-3 {
    left: 12.5%; }
  .col-md-push-4 {
    left: 16.66667%; }
  .col-md-push-5 {
    left: 20.83333%; }
  .col-md-push-6 {
    left: 25%; }
  .col-md-push-7 {
    left: 29.16667%; }
  .col-md-push-8 {
    left: 33.33333%; }
  .col-md-push-9 {
    left: 37.5%; }
  .col-md-push-10 {
    left: 41.66667%; }
  .col-md-push-11 {
    left: 45.83333%; }
  .col-md-push-12 {
    left: 50%; }
  .col-md-push-13 {
    left: 54.16667%; }
  .col-md-push-14 {
    left: 58.33333%; }
  .col-md-push-15 {
    left: 62.5%; }
  .col-md-push-16 {
    left: 66.66667%; }
  .col-md-push-17 {
    left: 70.83333%; }
  .col-md-push-18 {
    left: 75%; }
  .col-md-push-19 {
    left: 79.16667%; }
  .col-md-push-20 {
    left: 83.33333%; }
  .col-md-push-21 {
    left: 87.5%; }
  .col-md-push-22 {
    left: 91.66667%; }
  .col-md-push-23 {
    left: 95.83333%; }
  .col-md-push-24 {
    left: 100%; }
  .col-md-offset-0 {
    margin-left: 0%; }
  .col-md-offset-1 {
    margin-left: 4.16667%; }
  .col-md-offset-2 {
    margin-left: 8.33333%; }
  .col-md-offset-3 {
    margin-left: 12.5%; }
  .col-md-offset-4 {
    margin-left: 16.66667%; }
  .col-md-offset-5 {
    margin-left: 20.83333%; }
  .col-md-offset-6 {
    margin-left: 25%; }
  .col-md-offset-7 {
    margin-left: 29.16667%; }
  .col-md-offset-8 {
    margin-left: 33.33333%; }
  .col-md-offset-9 {
    margin-left: 37.5%; }
  .col-md-offset-10 {
    margin-left: 41.66667%; }
  .col-md-offset-11 {
    margin-left: 45.83333%; }
  .col-md-offset-12 {
    margin-left: 50%; }
  .col-md-offset-13 {
    margin-left: 54.16667%; }
  .col-md-offset-14 {
    margin-left: 58.33333%; }
  .col-md-offset-15 {
    margin-left: 62.5%; }
  .col-md-offset-16 {
    margin-left: 66.66667%; }
  .col-md-offset-17 {
    margin-left: 70.83333%; }
  .col-md-offset-18 {
    margin-left: 75%; }
  .col-md-offset-19 {
    margin-left: 79.16667%; }
  .col-md-offset-20 {
    margin-left: 83.33333%; }
  .col-md-offset-21 {
    margin-left: 87.5%; }
  .col-md-offset-22 {
    margin-left: 91.66667%; }
  .col-md-offset-23 {
    margin-left: 95.83333%; }
  .col-md-offset-24 {
    margin-left: 100%; } }

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24 {
    float: left; }
  .col-lg-1 {
    width: 4.16667%; }
  .col-lg-2 {
    width: 8.33333%; }
  .col-lg-3 {
    width: 12.5%; }
  .col-lg-4 {
    width: 16.66667%; }
  .col-lg-5 {
    width: 20.83333%; }
  .col-lg-6 {
    width: 25%; }
  .col-lg-7 {
    width: 29.16667%; }
  .col-lg-8 {
    width: 33.33333%; }
  .col-lg-9 {
    width: 37.5%; }
  .col-lg-10 {
    width: 41.66667%; }
  .col-lg-11 {
    width: 45.83333%; }
  .col-lg-12 {
    width: 50%; }
  .col-lg-13 {
    width: 54.16667%; }
  .col-lg-14 {
    width: 58.33333%; }
  .col-lg-15 {
    width: 62.5%; }
  .col-lg-16 {
    width: 66.66667%; }
  .col-lg-17 {
    width: 70.83333%; }
  .col-lg-18 {
    width: 75%; }
  .col-lg-19 {
    width: 79.16667%; }
  .col-lg-20 {
    width: 83.33333%; }
  .col-lg-21 {
    width: 87.5%; }
  .col-lg-22 {
    width: 91.66667%; }
  .col-lg-23 {
    width: 95.83333%; }
  .col-lg-24 {
    width: 100%; }
  .col-lg-pull-0 {
    right: auto; }
  .col-lg-pull-1 {
    right: 4.16667%; }
  .col-lg-pull-2 {
    right: 8.33333%; }
  .col-lg-pull-3 {
    right: 12.5%; }
  .col-lg-pull-4 {
    right: 16.66667%; }
  .col-lg-pull-5 {
    right: 20.83333%; }
  .col-lg-pull-6 {
    right: 25%; }
  .col-lg-pull-7 {
    right: 29.16667%; }
  .col-lg-pull-8 {
    right: 33.33333%; }
  .col-lg-pull-9 {
    right: 37.5%; }
  .col-lg-pull-10 {
    right: 41.66667%; }
  .col-lg-pull-11 {
    right: 45.83333%; }
  .col-lg-pull-12 {
    right: 50%; }
  .col-lg-pull-13 {
    right: 54.16667%; }
  .col-lg-pull-14 {
    right: 58.33333%; }
  .col-lg-pull-15 {
    right: 62.5%; }
  .col-lg-pull-16 {
    right: 66.66667%; }
  .col-lg-pull-17 {
    right: 70.83333%; }
  .col-lg-pull-18 {
    right: 75%; }
  .col-lg-pull-19 {
    right: 79.16667%; }
  .col-lg-pull-20 {
    right: 83.33333%; }
  .col-lg-pull-21 {
    right: 87.5%; }
  .col-lg-pull-22 {
    right: 91.66667%; }
  .col-lg-pull-23 {
    right: 95.83333%; }
  .col-lg-pull-24 {
    right: 100%; }
  .col-lg-push-0 {
    left: auto; }
  .col-lg-push-1 {
    left: 4.16667%; }
  .col-lg-push-2 {
    left: 8.33333%; }
  .col-lg-push-3 {
    left: 12.5%; }
  .col-lg-push-4 {
    left: 16.66667%; }
  .col-lg-push-5 {
    left: 20.83333%; }
  .col-lg-push-6 {
    left: 25%; }
  .col-lg-push-7 {
    left: 29.16667%; }
  .col-lg-push-8 {
    left: 33.33333%; }
  .col-lg-push-9 {
    left: 37.5%; }
  .col-lg-push-10 {
    left: 41.66667%; }
  .col-lg-push-11 {
    left: 45.83333%; }
  .col-lg-push-12 {
    left: 50%; }
  .col-lg-push-13 {
    left: 54.16667%; }
  .col-lg-push-14 {
    left: 58.33333%; }
  .col-lg-push-15 {
    left: 62.5%; }
  .col-lg-push-16 {
    left: 66.66667%; }
  .col-lg-push-17 {
    left: 70.83333%; }
  .col-lg-push-18 {
    left: 75%; }
  .col-lg-push-19 {
    left: 79.16667%; }
  .col-lg-push-20 {
    left: 83.33333%; }
  .col-lg-push-21 {
    left: 87.5%; }
  .col-lg-push-22 {
    left: 91.66667%; }
  .col-lg-push-23 {
    left: 95.83333%; }
  .col-lg-push-24 {
    left: 100%; }
  .col-lg-offset-0 {
    margin-left: 0%; }
  .col-lg-offset-1 {
    margin-left: 4.16667%; }
  .col-lg-offset-2 {
    margin-left: 8.33333%; }
  .col-lg-offset-3 {
    margin-left: 12.5%; }
  .col-lg-offset-4 {
    margin-left: 16.66667%; }
  .col-lg-offset-5 {
    margin-left: 20.83333%; }
  .col-lg-offset-6 {
    margin-left: 25%; }
  .col-lg-offset-7 {
    margin-left: 29.16667%; }
  .col-lg-offset-8 {
    margin-left: 33.33333%; }
  .col-lg-offset-9 {
    margin-left: 37.5%; }
  .col-lg-offset-10 {
    margin-left: 41.66667%; }
  .col-lg-offset-11 {
    margin-left: 45.83333%; }
  .col-lg-offset-12 {
    margin-left: 50%; }
  .col-lg-offset-13 {
    margin-left: 54.16667%; }
  .col-lg-offset-14 {
    margin-left: 58.33333%; }
  .col-lg-offset-15 {
    margin-left: 62.5%; }
  .col-lg-offset-16 {
    margin-left: 66.66667%; }
  .col-lg-offset-17 {
    margin-left: 70.83333%; }
  .col-lg-offset-18 {
    margin-left: 75%; }
  .col-lg-offset-19 {
    margin-left: 79.16667%; }
  .col-lg-offset-20 {
    margin-left: 83.33333%; }
  .col-lg-offset-21 {
    margin-left: 87.5%; }
  .col-lg-offset-22 {
    margin-left: 91.66667%; }
  .col-lg-offset-23 {
    margin-left: 95.83333%; }
  .col-lg-offset-24 {
    margin-left: 100%; } }

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0; }

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5; }

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold; }

input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal; }

input[type="file"] {
  display: block; }

input[type="range"] {
  display: block;
  width: 100%; }

select[multiple],
select[size] {
  height: auto; }

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555; }

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  .form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .form-control::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #999; }
  .form-control::-webkit-input-placeholder {
    color: #999; }
  .form-control[disabled], .form-control[readonly],
  fieldset[disabled] .form-control {
    background-color: #eeeeee;
    opacity: 1; }
  .form-control[disabled],
  fieldset[disabled] .form-control {
    cursor: not-allowed; }

textarea.form-control {
  height: auto; }

input[type="search"] {
  -webkit-appearance: none; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    line-height: 34px; }
    input[type="date"].input-sm,
    .input-group-sm input[type="date"],
    input[type="time"].input-sm,
    .input-group-sm
    input[type="time"],
    input[type="datetime-local"].input-sm,
    .input-group-sm
    input[type="datetime-local"],
    input[type="month"].input-sm,
    .input-group-sm
    input[type="month"] {
      line-height: 30px; }
    input[type="date"].input-lg,
    .input-group-lg input[type="date"],
    input[type="time"].input-lg,
    .input-group-lg
    input[type="time"],
    input[type="datetime-local"].input-lg,
    .input-group-lg
    input[type="datetime-local"],
    input[type="month"].input-lg,
    .input-group-lg
    input[type="month"] {
      line-height: 46px; } }

.form-group {
  margin-bottom: 15px; }

.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px; }
  .radio label,
  .checkbox label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer; }

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9; }

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px; }

.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer; }

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px; }

input[type="radio"][disabled], input[type="radio"].disabled,
fieldset[disabled] input[type="radio"],
input[type="checkbox"][disabled],
input[type="checkbox"].disabled,
fieldset[disabled]
input[type="checkbox"] {
  cursor: not-allowed; }

.radio-inline.disabled,
fieldset[disabled] .radio-inline,
.checkbox-inline.disabled,
fieldset[disabled]
.checkbox-inline {
  cursor: not-allowed; }

.radio.disabled label,
fieldset[disabled] .radio label,
.checkbox.disabled label,
fieldset[disabled]
.checkbox label {
  cursor: not-allowed; }

.form-control-static {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
  min-height: 34px; }
  .form-control-static.input-lg, .form-control-static.input-sm {
    padding-left: 0;
    padding-right: 0; }

.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

select.input-sm {
  height: 30px;
  line-height: 30px; }

textarea.input-sm,
select[multiple].input-sm {
  height: auto; }

.form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.form-group-sm select.form-control {
  height: 30px;
  line-height: 30px; }

.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto; }

.form-group-sm .form-control-static {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  min-height: 32px; }

.input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px; }

select.input-lg {
  height: 46px;
  line-height: 46px; }

textarea.input-lg,
select[multiple].input-lg {
  height: auto; }

.form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px; }

.form-group-lg select.form-control {
  height: 46px;
  line-height: 46px; }

.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto; }

.form-group-lg .form-control-static {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  min-height: 38px; }

.has-feedback {
  position: relative; }
  .has-feedback .form-control {
    padding-right: 42.5px; }

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none; }

.input-lg + .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px; }

.input-sm + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px; }

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #3c763d; }

.has-success .form-control {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .has-success .form-control:focus {
    border-color: #2b542c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168; }

.has-success .input-group-addon {
  color: #3c763d;
  border-color: #3c763d;
  background-color: #dff0d8; }

.has-success .form-control-feedback {
  color: #3c763d; }

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #8a6d3b; }

.has-warning .form-control {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .has-warning .form-control:focus {
    border-color: #66512c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b; }

.has-warning .input-group-addon {
  color: #8a6d3b;
  border-color: #8a6d3b;
  background-color: #fcf8e3; }

.has-warning .form-control-feedback {
  color: #8a6d3b; }

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #a94442; }

.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .has-error .form-control:focus {
    border-color: #843534;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483; }

.has-error .input-group-addon {
  color: #a94442;
  border-color: #a94442;
  background-color: #f2dede; }

.has-error .form-control-feedback {
  color: #a94442; }

.has-feedback label ~ .form-control-feedback {
  top: 25px; }

.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0; }

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373; }

@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .form-inline .form-control-static {
    display: inline-block; }
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle; }
    .form-inline .input-group .input-group-addon,
    .form-inline .input-group .input-group-btn,
    .form-inline .input-group .form-control {
      width: auto; }
  .form-inline .input-group > .form-control {
    width: 100%; }
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .radio,
  .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle; }
    .form-inline .radio label,
    .form-inline .checkbox label {
      padding-left: 0; }
  .form-inline .radio input[type="radio"],
  .form-inline .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0; }
  .form-inline .has-feedback .form-control-feedback {
    top: 0; } }

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px; }

.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 27px; }

.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px; }
  .form-horizontal .form-group:before, .form-horizontal .form-group:after {
    content: " ";
    display: table; }
  .form-horizontal .form-group:after {
    clear: both; }

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px; } }

.form-horizontal .has-feedback .form-control-feedback {
  right: 15px; }

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 14.33333px; } }

@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px; } }

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  .fade.in {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.in {
    display: block; }

tr.collapse.in {
  display: table-row; }

tbody.collapse.in {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease; }

.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none; }
  .nav:before, .nav:after {
    content: " ";
    display: table; }
  .nav:after {
    clear: both; }
  .nav > li {
    position: relative;
    display: block; }
    .nav > li > a {
      position: relative;
      display: block;
      padding: 10px 15px; }
      .nav > li > a:hover, .nav > li > a:focus {
        text-decoration: none;
        background-color: #eeeeee; }
    .nav > li.disabled > a {
      color: #777777; }
      .nav > li.disabled > a:hover, .nav > li.disabled > a:focus {
        color: #777777;
        text-decoration: none;
        background-color: transparent;
        cursor: not-allowed; }
  .nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
    background-color: #eeeeee;
    border-color: #337ab7; }
  .nav .nav-divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5; }
  .nav > li > a > img {
    max-width: none; }

.nav-tabs {
  border-bottom: 1px solid #ddd; }
  .nav-tabs > li {
    float: left;
    margin-bottom: -1px; }
    .nav-tabs > li > a {
      margin-right: 2px;
      line-height: 1.42857;
      border: 1px solid transparent;
      border-radius: 4px 4px 0 0; }
      .nav-tabs > li > a:hover {
        border-color: #eeeeee #eeeeee #ddd; }
    .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
      color: #555555;
      background-color: #fff;
      border: 1px solid #ddd;
      border-bottom-color: transparent;
      cursor: default; }

.nav-pills > li {
  float: left; }
  .nav-pills > li > a {
    border-radius: 4px; }
  .nav-pills > li + li {
    margin-left: 2px; }
  .nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
    color: #fff;
    background-color: #337ab7; }

.nav-stacked > li {
  float: none; }
  .nav-stacked > li + li {
    margin-top: 2px;
    margin-left: 0; }

.nav-justified, .nav-tabs.nav-justified {
  width: 100%; }
  .nav-justified > li, .nav-tabs.nav-justified > li {
    float: none; }
    .nav-justified > li > a, .nav-tabs.nav-justified > li > a {
      text-align: center;
      margin-bottom: 5px; }
  .nav-justified > .dropdown .dropdown-menu {
    top: auto;
    left: auto; }
  @media (min-width: 768px) {
    .nav-justified > li, .nav-tabs.nav-justified > li {
      display: table-cell;
      width: 1%; }
      .nav-justified > li > a, .nav-tabs.nav-justified > li > a {
        margin-bottom: 0; } }

.nav-tabs-justified, .nav-tabs.nav-justified {
  border-bottom: 0; }
  .nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
    margin-right: 0;
    border-radius: 4px; }
  .nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a,
  .nav-tabs-justified > .active > a:hover,
  .nav-tabs.nav-justified > .active > a:hover,
  .nav-tabs-justified > .active > a:focus,
  .nav-tabs.nav-justified > .active > a:focus {
    border: 1px solid #ddd; }
  @media (min-width: 768px) {
    .nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
      border-bottom: 1px solid #ddd;
      border-radius: 4px 4px 0 0; }
    .nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a,
    .nav-tabs-justified > .active > a:hover,
    .nav-tabs.nav-justified > .active > a:hover,
    .nav-tabs-justified > .active > a:focus,
    .nav-tabs.nav-justified > .active > a:focus {
      border-bottom-color: #fff; } }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

.navbar {
  position: relative;
  min-height: 105px;
  margin-bottom: 0;
  border: 1px solid transparent; }
  .navbar:before, .navbar:after {
    content: " ";
    display: table; }
  .navbar:after {
    clear: both; }
  @media (min-width: 768px) {
    .navbar {
      border-radius: 0; } }

.navbar-header:before, .navbar-header:after {
  content: " ";
  display: table; }

.navbar-header:after {
  clear: both; }

@media (min-width: 768px) {
  .navbar-header {
    float: left; } }

.navbar-collapse {
  overflow-x: visible;
  padding-right: 0;
  padding-left: 0;
  border-top: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  -webkit-overflow-scrolling: touch; }
  .navbar-collapse:before, .navbar-collapse:after {
    content: " ";
    display: table; }
  .navbar-collapse:after {
    clear: both; }
  .navbar-collapse.in {
    overflow-y: auto; }
  @media (min-width: 768px) {
    .navbar-collapse {
      width: auto;
      border-top: 0;
      box-shadow: none; }
      .navbar-collapse.collapse {
        display: block !important;
        height: auto !important;
        padding-bottom: 0;
        overflow: visible !important; }
      .navbar-collapse.in {
        overflow-y: visible; }
      .navbar-fixed-top .navbar-collapse,
      .navbar-static-top .navbar-collapse,
      .navbar-fixed-bottom .navbar-collapse {
        padding-left: 0;
        padding-right: 0; } }

.navbar-fixed-top .navbar-collapse,
.navbar-fixed-bottom .navbar-collapse {
  max-height: 340px; }
  @media (max-device-width: 480px) and (orientation: landscape) {
    .navbar-fixed-top .navbar-collapse,
    .navbar-fixed-bottom .navbar-collapse {
      max-height: 200px; } }

.container > .navbar-header,
.container > .navbar-collapse,
.container-fluid > .navbar-header,
.container-fluid > .navbar-collapse {
  margin-right: 0;
  margin-left: 0; }
  @media (min-width: 768px) {
    .container > .navbar-header,
    .container > .navbar-collapse,
    .container-fluid > .navbar-header,
    .container-fluid > .navbar-collapse {
      margin-right: 0;
      margin-left: 0; } }

.navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px; }
  @media (min-width: 768px) {
    .navbar-static-top {
      border-radius: 0; } }

.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030; }
  @media (min-width: 768px) {
    .navbar-fixed-top,
    .navbar-fixed-bottom {
      border-radius: 0; } }

.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px; }

.navbar-fixed-bottom {
  bottom: 0;
  margin-bottom: 0;
  border-width: 1px 0 0; }

.navbar-brand {
  float: left;
  padding: 0 0;
  font-size: 18px;
  line-height: 20px;
  height: 105px; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }
  .navbar-brand > img {
    display: block; }
  @media (min-width: 768px) {
    .navbar > .container .navbar-brand,
    .navbar > .container-fluid .navbar-brand {
      margin-left: 0; } }

.navbar-toggle {
  position: relative;
  float: right;
  margin-right: 0;
  padding: 9px 10px;
  margin-top: 35.5px;
  margin-bottom: 35.5px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px; }
  .navbar-toggle:focus {
    outline: 0; }
  .navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px; }
  .navbar-toggle .icon-bar + .icon-bar {
    margin-top: 4px; }
  @media (min-width: 768px) {
    .navbar-toggle {
      display: none; } }

.navbar-nav {
  margin: 0 0; }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px; }
  @media (max-width: 767px) {
    .navbar-nav .open .dropdown-menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      box-shadow: none; }
      .navbar-nav .open .dropdown-menu > li > a,
      .navbar-nav .open .dropdown-menu .dropdown-header {
        padding: 5px 15px 5px 25px; }
      .navbar-nav .open .dropdown-menu > li > a {
        line-height: 20px; }
        .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-nav .open .dropdown-menu > li > a:focus {
          background-image: none; } }
  @media (min-width: 768px) {
    .navbar-nav {
      float: left;
      margin: 0; }
      .navbar-nav > li {
        float: left; }
        .navbar-nav > li > a {
          padding-top: 0;
          padding-bottom: 0; } }

.navbar-form {
  margin-left: 0;
  margin-right: 0;
  padding: 10px 0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
  margin-top: 35.5px;
  margin-bottom: 35.5px; }
  @media (min-width: 768px) {
    .navbar-form .form-group {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle; }
    .navbar-form .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .navbar-form .form-control-static {
      display: inline-block; }
    .navbar-form .input-group {
      display: inline-table;
      vertical-align: middle; }
      .navbar-form .input-group .input-group-addon,
      .navbar-form .input-group .input-group-btn,
      .navbar-form .input-group .form-control {
        width: auto; }
    .navbar-form .input-group > .form-control {
      width: 100%; }
    .navbar-form .control-label {
      margin-bottom: 0;
      vertical-align: middle; }
    .navbar-form .radio,
    .navbar-form .checkbox {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      vertical-align: middle; }
      .navbar-form .radio label,
      .navbar-form .checkbox label {
        padding-left: 0; }
    .navbar-form .radio input[type="radio"],
    .navbar-form .checkbox input[type="checkbox"] {
      position: relative;
      margin-left: 0; }
    .navbar-form .has-feedback .form-control-feedback {
      top: 0; } }
  @media (max-width: 767px) {
    .navbar-form .form-group {
      margin-bottom: 5px; }
      .navbar-form .form-group:last-child {
        margin-bottom: 0; } }
  @media (min-width: 768px) {
    .navbar-form {
      width: auto;
      border: 0;
      margin-left: 0;
      margin-right: 0;
      padding-top: 0;
      padding-bottom: 0;
      -webkit-box-shadow: none;
      box-shadow: none; } }

.navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  margin-bottom: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.navbar-btn {
  margin-top: 35.5px;
  margin-bottom: 35.5px; }
  .navbar-btn.btn-sm {
    margin-top: 37.5px;
    margin-bottom: 37.5px; }
  .navbar-btn.btn-xs {
    margin-top: 41.5px;
    margin-bottom: 41.5px; }

.navbar-text {
  margin-top: 42.5px;
  margin-bottom: 42.5px; }
  @media (min-width: 768px) {
    .navbar-text {
      float: left;
      margin-left: 0;
      margin-right: 0; } }

@media (min-width: 768px) {
  .navbar-left {
    float: left !important; }
  .navbar-right {
    float: right !important;
    margin-right: 0; }
    .navbar-right ~ .navbar-right {
      margin-right: 0; } }

.navbar-default {
  background-color: transparent;
  border-color: transparent; }
  .navbar-default .navbar-brand {
    color: #000000; }
    .navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
      color: black;
      background-color: transparent; }
  .navbar-default .navbar-text {
    color: transparent; }
  .navbar-default .navbar-nav > li > a {
    color: #000000; }
    .navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
      color: #00adf1;
      background-color: transparent; }
  .navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    color: #00adf1;
    background-color: rgba(0, 0, 0, 0); }
  .navbar-default .navbar-nav > .disabled > a, .navbar-default .navbar-nav > .disabled > a:hover, .navbar-default .navbar-nav > .disabled > a:focus {
    color: #ccc;
    background-color: transparent; }
  .navbar-default .navbar-toggle {
    border-color: #transparent; }
    .navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
      background-color: transparent; }
    .navbar-default .navbar-toggle .icon-bar {
      background-color: #00adf1; }
  .navbar-default .navbar-collapse,
  .navbar-default .navbar-form {
    border-color: transparent; }
  .navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
    background-color: rgba(0, 0, 0, 0);
    color: #00adf1; }
  @media (max-width: 767px) {
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
      color: #000000; }
      .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #00adf1;
        background-color: transparent; }
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
      color: #00adf1;
      background-color: rgba(0, 0, 0, 0); }
    .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
      color: #ccc;
      background-color: transparent; } }
  .navbar-default .navbar-link {
    color: #000000; }
    .navbar-default .navbar-link:hover {
      color: #00adf1; }
  .navbar-default .btn-link {
    color: #000000; }
    .navbar-default .btn-link:hover, .navbar-default .btn-link:focus {
      color: #00adf1; }
    .navbar-default .btn-link[disabled]:hover, .navbar-default .btn-link[disabled]:focus,
    fieldset[disabled] .navbar-default .btn-link:hover,
    fieldset[disabled] .navbar-default .btn-link:focus {
      color: #ccc; }

.navbar-inverse {
  background-color: #222;
  border-color: #090909; }
  .navbar-inverse .navbar-brand {
    color: #9d9d9d; }
    .navbar-inverse .navbar-brand:hover, .navbar-inverse .navbar-brand:focus {
      color: #fff;
      background-color: transparent; }
  .navbar-inverse .navbar-text {
    color: #9d9d9d; }
  .navbar-inverse .navbar-nav > li > a {
    color: #9d9d9d; }
    .navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus {
      color: #fff;
      background-color: transparent; }
  .navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
    color: #fff;
    background-color: #090909; }
  .navbar-inverse .navbar-nav > .disabled > a, .navbar-inverse .navbar-nav > .disabled > a:hover, .navbar-inverse .navbar-nav > .disabled > a:focus {
    color: #444;
    background-color: transparent; }
  .navbar-inverse .navbar-toggle {
    border-color: #333; }
    .navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
      background-color: #333; }
    .navbar-inverse .navbar-toggle .icon-bar {
      background-color: #fff; }
  .navbar-inverse .navbar-collapse,
  .navbar-inverse .navbar-form {
    border-color: #101010; }
  .navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
    background-color: #090909;
    color: #fff; }
  @media (max-width: 767px) {
    .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
      border-color: #090909; }
    .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
      background-color: #090909; }
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
      color: #9d9d9d; }
      .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #fff;
        background-color: transparent; }
    .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
      color: #fff;
      background-color: #090909; }
    .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
      color: #444;
      background-color: transparent; } }
  .navbar-inverse .navbar-link {
    color: #9d9d9d; }
    .navbar-inverse .navbar-link:hover {
      color: #fff; }
  .navbar-inverse .btn-link {
    color: #9d9d9d; }
    .navbar-inverse .btn-link:hover, .navbar-inverse .btn-link:focus {
      color: #fff; }
    .navbar-inverse .btn-link[disabled]:hover, .navbar-inverse .btn-link[disabled]:focus,
    fieldset[disabled] .navbar-inverse .btn-link:hover,
    fieldset[disabled] .navbar-inverse .btn-link:focus {
      color: #444; }

.modal-open {
  overflow: hidden; }

.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0; }
  .modal.fade .modal-dialog {
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    -o-transform: translate(0, -25%);
    transform: translate(0, -25%);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -moz-transition: -moz-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out; }
  .modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0); }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px; }

.modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0;
    filter: alpha(opacity=0); }
  .modal-backdrop.in {
    opacity: 0.5;
    filter: alpha(opacity=50); }

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  min-height: 16.42857px; }

.modal-header .close {
  margin-top: -2px; }

.modal-title {
  margin: 0;
  line-height: 1.42857; }

.modal-body {
  position: relative;
  padding: 15px; }

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5; }
  .modal-footer:before, .modal-footer:after {
    content: " ";
    display: table; }
  .modal-footer:after {
    clear: both; }
  .modal-footer .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0; }
  .modal-footer .btn-group .btn + .btn {
    margin-left: -1px; }
  .modal-footer .btn-block + .btn-block {
    margin-left: 0; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto; }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); }
  .modal-sm {
    width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    width: 900px; } }

.clearfix:before, .clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.pull-right {
  float: right !important; }

.pull-left {
  float: left !important; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.invisible {
  visibility: hidden; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.hidden {
  display: none !important; }

.affix {
  position: fixed; }

@-ms-viewport {
  width: device-width; }

.visible-xs {
  display: none !important; }

.visible-sm {
  display: none !important; }

.visible-md {
  display: none !important; }

.visible-lg {
  display: none !important; }

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important; }

@media (max-width: 767px) {
  .visible-xs {
    display: block !important; }
  table.visible-xs {
    display: table; }
  tr.visible-xs {
    display: table-row !important; }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important; } }

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important; } }

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important; } }

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important; }
  table.visible-sm {
    display: table; }
  tr.visible-sm {
    display: table-row !important; }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important; }
  table.visible-md {
    display: table; }
  tr.visible-md {
    display: table-row !important; }
  th.visible-md,
  td.visible-md {
    display: table-cell !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important; } }

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important; }
  table.visible-lg {
    display: table; }
  tr.visible-lg {
    display: table-row !important; }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important; } }

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important; } }

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important; } }

.visible-print {
  display: none !important; }

@media print {
  .visible-print {
    display: block !important; }
  table.visible-print {
    display: table; }
  tr.visible-print {
    display: table-row !important; }
  th.visible-print,
  td.visible-print {
    display: table-cell !important; } }

.visible-print-block {
  display: none !important; }
  @media print {
    .visible-print-block {
      display: block !important; } }

.visible-print-inline {
  display: none !important; }
  @media print {
    .visible-print-inline {
      display: inline !important; } }

.visible-print-inline-block {
  display: none !important; }
  @media print {
    .visible-print-inline-block {
      display: inline-block !important; } }

@media print {
  .hidden-print {
    display: none !important; } }

.main-slider h3 {
  color: #00adf1;
  font-size: 35px;
  font-weight: 700;
  text-align: left;
  margin-top: 15px; }

.main-slider .slick-dots {
  display: inline-block !important;
  width: auto;
  right: 0; }

.main-slider .slick-dots li {
  background: #8e8e8e; }

.main-slider .main-slider__text {
  margin: 15px 0 30px; }

.main-slider .main-slider__controls a {
  margin-right: 20px; }

@media all and (max-width: 767px) {
  .main-slider .slick-arrow {
    display: none !important; }
  .main-slider .main-slider__controls a {
    margin-right: 2px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px; }
  .main-slider .slick-dots {
    width: 100%;
    bottom: -20px; } }

.main-slider .slick-arrow {
  top: 34%; }

.main-slider .slider__item {
  user-select: none; }

.main-slider__outer {
  padding-bottom: 0 !important; }

.main-slider__separator {
  margin-top: 40px;
  background-color: #00adf1;
  height: 1px;
  width: 100%;
  box-shadow: inset 0 0 1px white; }

@-webkit-keyframes fromRightAnim1 {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 50%;
    opacity: 1; } }

@-moz-keyframes fromRightAnim1 {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 50%;
    opacity: 1; } }

@-ms-keyframes fromRightAnim1 {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 50%;
    opacity: 1; } }

@keyframes fromRightAnim1 {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 50%;
    opacity: 1; } }

@-webkit-keyframes fromRightAnim1Sm {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@-moz-keyframes fromRightAnim1Sm {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@-ms-keyframes fromRightAnim1Sm {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@keyframes fromRightAnim1Sm {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@-webkit-keyframes fromRightAnim2 {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 50%;
    opacity: 1; } }

@-moz-keyframes fromRightAnim2 {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 50%;
    opacity: 1; } }

@-ms-keyframes fromRightAnim2 {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 50%;
    opacity: 1; } }

@keyframes fromRightAnim2 {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 50%;
    opacity: 1; } }

@-webkit-keyframes fromRightAnim2Sm {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@-moz-keyframes fromRightAnim2Sm {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@-ms-keyframes fromRightAnim2Sm {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@keyframes fromRightAnim2Sm {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@-webkit-keyframes fromRightAnim3 {
  0% {
    left: 110%;
    opacity: 0; }
  1% {
    left: 55%;
    opacity: 0; }
  100% {
    left: 55%;
    opacity: 1; } }

@-moz-keyframes fromRightAnim3 {
  0% {
    left: 110%;
    opacity: 0; }
  1% {
    left: 55%;
    opacity: 0; }
  100% {
    left: 55%;
    opacity: 1; } }

@-ms-keyframes fromRightAnim3 {
  0% {
    left: 110%;
    opacity: 0; }
  1% {
    left: 55%;
    opacity: 0; }
  100% {
    left: 55%;
    opacity: 1; } }

@keyframes fromRightAnim3 {
  0% {
    left: 110%;
    opacity: 0; }
  1% {
    left: 55%;
    opacity: 0; }
  100% {
    left: 55%;
    opacity: 1; } }

@-webkit-keyframes fromRightAnim3Sm {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@-moz-keyframes fromRightAnim3Sm {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@-ms-keyframes fromRightAnim3Sm {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@keyframes fromRightAnim3Sm {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@-webkit-keyframes fromRightAnim4 {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 10%;
    opacity: 1; } }

@-moz-keyframes fromRightAnim4 {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 10%;
    opacity: 1; } }

@-ms-keyframes fromRightAnim4 {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 10%;
    opacity: 1; } }

@keyframes fromRightAnim4 {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 10%;
    opacity: 1; } }

@-webkit-keyframes fromRightAnim4Sm {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 5%;
    opacity: 1; } }

@-moz-keyframes fromRightAnim4Sm {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 5%;
    opacity: 1; } }

@-ms-keyframes fromRightAnim4Sm {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 5%;
    opacity: 1; } }

@keyframes fromRightAnim4Sm {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 5%;
    opacity: 1; } }

@-webkit-keyframes fromRightAnim5 {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 0;
    opacity: 1; } }

@-moz-keyframes fromRightAnim5 {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 0;
    opacity: 1; } }

@-ms-keyframes fromRightAnim5 {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 0;
    opacity: 1; } }

@keyframes fromRightAnim5 {
  0% {
    left: 110%;
    opacity: 0; }
  100% {
    left: 0;
    opacity: 1; } }

@-webkit-keyframes fromLeftAnim1 {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 50%;
    opacity: 1; } }

@-moz-keyframes fromLeftAnim1 {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 50%;
    opacity: 1; } }

@-ms-keyframes fromLeftAnim1 {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 50%;
    opacity: 1; } }

@keyframes fromLeftAnim1 {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 50%;
    opacity: 1; } }

@-webkit-keyframes fromLeftAnim1Sm {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@-moz-keyframes fromLeftAnim1Sm {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@-ms-keyframes fromLeftAnim1Sm {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@keyframes fromLeftAnim1Sm {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@-webkit-keyframes fromLeftAnim2 {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 50%;
    opacity: 1; } }

@-moz-keyframes fromLeftAnim2 {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 50%;
    opacity: 1; } }

@-ms-keyframes fromLeftAnim2 {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 50%;
    opacity: 1; } }

@keyframes fromLeftAnim2 {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 50%;
    opacity: 1; } }

@-webkit-keyframes fromLeftAnim2Sm {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@-moz-keyframes fromLeftAnim2Sm {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@-ms-keyframes fromLeftAnim2Sm {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@keyframes fromLeftAnim2Sm {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@-webkit-keyframes fromLeftAnim3 {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 55%;
    opacity: 1; } }

@-moz-keyframes fromLeftAnim3 {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 55%;
    opacity: 1; } }

@-ms-keyframes fromLeftAnim3 {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 55%;
    opacity: 1; } }

@keyframes fromLeftAnim3 {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 55%;
    opacity: 1; } }

@-webkit-keyframes fromLeftAnim3Sm {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@-moz-keyframes fromLeftAnim3Sm {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@-ms-keyframes fromLeftAnim3Sm {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@keyframes fromLeftAnim3Sm {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 45%;
    opacity: 1; } }

@-webkit-keyframes fromLeftAnim4 {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 10%;
    opacity: 1; } }

@-moz-keyframes fromLeftAnim4 {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 10%;
    opacity: 1; } }

@-ms-keyframes fromLeftAnim4 {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 10%;
    opacity: 1; } }

@keyframes fromLeftAnim4 {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 10%;
    opacity: 1; } }

@-webkit-keyframes fromLeftAnim4Sm {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 5%;
    opacity: 1; } }

@-moz-keyframes fromLeftAnim4Sm {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 5%;
    opacity: 1; } }

@-ms-keyframes fromLeftAnim4Sm {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 5%;
    opacity: 1; } }

@keyframes fromLeftAnim4Sm {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 5%;
    opacity: 1; } }

@-webkit-keyframes fromLeftAnim5 {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 0;
    opacity: 1; } }

@-moz-keyframes fromLeftAnim5 {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 0;
    opacity: 1; } }

@-ms-keyframes fromLeftAnim5 {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 0;
    opacity: 1; } }

@keyframes fromLeftAnim5 {
  0% {
    left: -110%;
    opacity: 0; }
  100% {
    left: 0;
    opacity: 1; } }

@-webkit-keyframes toRightAnim1 {
  0% {
    left: 50%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-moz-keyframes toRightAnim1 {
  0% {
    left: 50%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-ms-keyframes toRightAnim1 {
  0% {
    left: 50%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@keyframes toRightAnim1 {
  0% {
    left: 50%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-webkit-keyframes toRightAnim1Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-moz-keyframes toRightAnim1Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-ms-keyframes toRightAnim1Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@keyframes toRightAnim1Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-webkit-keyframes toRightAnim2 {
  0% {
    left: 50%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-moz-keyframes toRightAnim2 {
  0% {
    left: 50%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-ms-keyframes toRightAnim2 {
  0% {
    left: 50%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@keyframes toRightAnim2 {
  0% {
    left: 50%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-webkit-keyframes toRightAnim2Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-moz-keyframes toRightAnim2Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-ms-keyframes toRightAnim2Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@keyframes toRightAnim2Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-webkit-keyframes toRightAnim3 {
  0% {
    left: 55%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-moz-keyframes toRightAnim3 {
  0% {
    left: 55%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-ms-keyframes toRightAnim3 {
  0% {
    left: 55%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@keyframes toRightAnim3 {
  0% {
    left: 55%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-webkit-keyframes toRightAnim3Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-moz-keyframes toRightAnim3Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-ms-keyframes toRightAnim3Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@keyframes toRightAnim3Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-webkit-keyframes toRightAnim4 {
  0% {
    left: 10%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-moz-keyframes toRightAnim4 {
  0% {
    left: 10%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-ms-keyframes toRightAnim4 {
  0% {
    left: 10%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@keyframes toRightAnim4 {
  0% {
    left: 10%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-webkit-keyframes toRightAnim4Sm {
  0% {
    left: 5%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-moz-keyframes toRightAnim4Sm {
  0% {
    left: 5%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-ms-keyframes toRightAnim4Sm {
  0% {
    left: 5%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@keyframes toRightAnim4Sm {
  0% {
    left: 5%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-webkit-keyframes toRightAnim5 {
  0% {
    left: 0%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-moz-keyframes toRightAnim5 {
  0% {
    left: 0%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-ms-keyframes toRightAnim5 {
  0% {
    left: 0%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@keyframes toRightAnim5 {
  0% {
    left: 0%;
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

@-webkit-keyframes toLeftAnim1 {
  0% {
    left: 50%;
    opacity: 1; }
  100% {
    left: 80%;
    opacity: 0; } }

@-moz-keyframes toLeftAnim1 {
  0% {
    left: 50%;
    opacity: 1; }
  100% {
    left: 80%;
    opacity: 0; } }

@-ms-keyframes toLeftAnim1 {
  0% {
    left: 50%;
    opacity: 1; }
  100% {
    left: 80%;
    opacity: 0; } }

@keyframes toLeftAnim1 {
  0% {
    left: 50%;
    opacity: 1; }
  100% {
    left: 80%;
    opacity: 0; } }

@-webkit-keyframes toLeftAnim1Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 80%;
    opacity: 0; } }

@-moz-keyframes toLeftAnim1Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 80%;
    opacity: 0; } }

@-ms-keyframes toLeftAnim1Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 80%;
    opacity: 0; } }

@keyframes toLeftAnim1Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 80%;
    opacity: 0; } }

@-webkit-keyframes toLeftAnim2 {
  0% {
    left: 50%;
    opacity: 1; }
  100% {
    left: 80%;
    opacity: 0; } }

@-moz-keyframes toLeftAnim2 {
  0% {
    left: 50%;
    opacity: 1; }
  100% {
    left: 80%;
    opacity: 0; } }

@-ms-keyframes toLeftAnim2 {
  0% {
    left: 50%;
    opacity: 1; }
  100% {
    left: 80%;
    opacity: 0; } }

@keyframes toLeftAnim2 {
  0% {
    left: 50%;
    opacity: 1; }
  100% {
    left: 80%;
    opacity: 0; } }

@-webkit-keyframes toLeftAnim2Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 80%;
    opacity: 0; } }

@-moz-keyframes toLeftAnim2Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 80%;
    opacity: 0; } }

@-ms-keyframes toLeftAnim2Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 80%;
    opacity: 0; } }

@keyframes toLeftAnim2Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 80%;
    opacity: 0; } }

@-webkit-keyframes toLeftAnim3 {
  0% {
    left: 55%;
    opacity: 1; }
  100% {
    left: 65%;
    opacity: 0; } }

@-moz-keyframes toLeftAnim3 {
  0% {
    left: 55%;
    opacity: 1; }
  100% {
    left: 65%;
    opacity: 0; } }

@-ms-keyframes toLeftAnim3 {
  0% {
    left: 55%;
    opacity: 1; }
  100% {
    left: 65%;
    opacity: 0; } }

@keyframes toLeftAnim3 {
  0% {
    left: 55%;
    opacity: 1; }
  100% {
    left: 65%;
    opacity: 0; } }

@-webkit-keyframes toLeftAnim3Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 45%;
    opacity: 0; } }

@-moz-keyframes toLeftAnim3Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 45%;
    opacity: 0; } }

@-ms-keyframes toLeftAnim3Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 45%;
    opacity: 0; } }

@keyframes toLeftAnim3Sm {
  0% {
    left: 45%;
    opacity: 1; }
  100% {
    left: 45%;
    opacity: 0; } }

@-webkit-keyframes toLeftAnim4 {
  0% {
    left: 10%;
    opacity: 1; }
  100% {
    left: -50%;
    opacity: 0; } }

@-moz-keyframes toLeftAnim4 {
  0% {
    left: 10%;
    opacity: 1; }
  100% {
    left: -50%;
    opacity: 0; } }

@-ms-keyframes toLeftAnim4 {
  0% {
    left: 10%;
    opacity: 1; }
  100% {
    left: -50%;
    opacity: 0; } }

@keyframes toLeftAnim4 {
  0% {
    left: 10%;
    opacity: 1; }
  100% {
    left: -50%;
    opacity: 0; } }

@-webkit-keyframes toLeftAnim4Sm {
  0% {
    left: 5%;
    opacity: 1; }
  100% {
    left: -50%;
    opacity: 0; } }

@-moz-keyframes toLeftAnim4Sm {
  0% {
    left: 5%;
    opacity: 1; }
  100% {
    left: -50%;
    opacity: 0; } }

@-ms-keyframes toLeftAnim4Sm {
  0% {
    left: 5%;
    opacity: 1; }
  100% {
    left: -50%;
    opacity: 0; } }

@keyframes toLeftAnim4Sm {
  0% {
    left: 5%;
    opacity: 1; }
  100% {
    left: -50%;
    opacity: 0; } }

@-webkit-keyframes toLeftAnim5 {
  0% {
    left: 0%;
    opacity: 1; }
  100% {
    left: -50%;
    opacity: 0; } }

@-moz-keyframes toLeftAnim5 {
  0% {
    left: 0%;
    opacity: 1; }
  100% {
    left: -50%;
    opacity: 0; } }

@-ms-keyframes toLeftAnim5 {
  0% {
    left: 0%;
    opacity: 1; }
  100% {
    left: -50%;
    opacity: 0; } }

@keyframes toLeftAnim5 {
  0% {
    left: 0%;
    opacity: 1; }
  100% {
    left: -50%;
    opacity: 0; } }

@-webkit-keyframes button-togle {
  25%, 75% {
    transform: translate(4px, 0px); }
  50%, 100% {
    transform: translate(0px, 0px); } }

@-moz-keyframes button-togle {
  25%, 75% {
    transform: translate(4px, 0px); }
  50%, 100% {
    transform: translate(0px, 0px); } }

@-ms-keyframes button-togle {
  25%, 75% {
    transform: translate(4px, 0px); }
  50%, 100% {
    transform: translate(0px, 0px); } }

@keyframes button-togle {
  25%, 75% {
    transform: translate(4px, 0px); }
  50%, 100% {
    transform: translate(0px, 0px); } }

@-webkit-keyframes show-text {
  0% {
    height: 0;
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    height: auto; } }

@-moz-keyframes show-text {
  0% {
    height: 0;
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    height: auto; } }

@-ms-keyframes show-text {
  0% {
    height: 0;
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    height: auto; } }

@keyframes show-text {
  0% {
    height: 0;
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    height: auto; } }

@-webkit-keyframes hide-text {
  0% {
    height: auto;
    opacity: 1; }
  100% {
    height: 0;
    opacity: 0; } }

@-moz-keyframes hide-text {
  0% {
    height: auto;
    opacity: 1; }
  100% {
    height: 0;
    opacity: 0; } }

@-ms-keyframes hide-text {
  0% {
    height: auto;
    opacity: 1; }
  100% {
    height: 0;
    opacity: 0; } }

@keyframes hide-text {
  0% {
    height: auto;
    opacity: 1; }
  100% {
    height: 0;
    opacity: 0; } }

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin-top: 25px; }

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-prev,
.slick-next {
  display: block;
  height: 40px;
  width: 40px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  padding: 0;
  border: none;
  outline: none;
  z-index: 9; }
  .slick-prev:before,
  .slick-next:before {
    content: '';
    display: block;
    height: 40px;
    width: 40px;
    opacity: 0.5;
    transform: rotate(45deg);
    transition: 0.3s; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.4; }

.slick-prev {
  left: 50px; }
  .slick-prev:before {
    border-left: 1px solid white;
    border-bottom: 1px solid white; }

.slick-next {
  right: 50px; }
  .slick-next:before {
    border-right: 1px solid white;
    border-top: 1px solid white; }

.slick-dots {
  position: absolute;
  bottom: 10px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 10px;
    background: white;
    margin: 0 5px;
    padding: 0; }
    .slick-dots li.slick-active, .slick-dots li:hover {
      background: #00adf1; }
    .slick-dots li button {
      border: 0;
      display: block;
      height: 10px;
      width: 10px;
      border-radius: 10px;
      background: transparent;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }

/* Tenant tools
------------------------------------
*/
.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

.text-up {
  text-transform: uppercase; }

.text-bold {
  font-weight: bold; }

.u-semibold {
  font-weight: 600; }

.font400 {
  font-weight: 400; }

.list-unstyled {
  list-style: none outside none;
  padding-left: 0; }

.borderNone {
  border-width: 0; }

.margin-auto {
  margin: 0 auto; }

/* Other Colors
------------------------------------
*/
.bg-white {
  background-color: #fff; }

.bg-transparent {
  background-color: transparent; }

.u-colorWhite {
  color: white; }

.text-master {
  color: #626262; }

.text-master-light {
  color: #e6e6e6; }

.text-black {
  color: #2c2c2c; }

.text-white {
  color: #fff; }

.text-complete {
  color: #48b0f7; }

.text-success {
  color: #10cfbd; }

.text-info {
  color: #3b4752; }

.text-warning {
  color: #f8d053; }

.text-warning-dark {
  color: #c9a843; }

.text-danger {
  color: #f55753; }

.text-primary {
  color: #7087b2; }

/* ----------------------------------------------------------------------------------------------------------
 Sensible defaults tools ----------------------------------------------------------------------------------------
---------------------------------------------------------------------------------------------------------- */
.u-textCap {
  text-transform: capitalize; }

.u-textUpper {
  text-transform: uppercase; }

.u-textLower {
  text-transform: lowercase; }

.u-textUnderline {
  text-decoration: underline; }

.u-textDanger {
  color: #a94442; }

.u-fw300 {
  font-weight: 300; }

.u-fw400 {
  font-weight: 400; }

.u-fw500 {
  font-weight: 500; }

.u-fw600 {
  font-weight: 600; }

.u-fw700 {
  font-weight: 700; }

.u-isActionable {
  cursor: pointer; }

.u-isDisabled {
  cursor: default; }

.u-isDraggable {
  cursor: move; }

.u-textBreak {
  word-wrap: break-word; }

.u-textCenter {
  text-align: center; }

.u-textLeft {
  text-align: left; }

.u-textRight {
  text-align: right; }

.u-textNoWrap {
  white-space: nowrap; }

.u-textInheritColor {
  color: inherit; }

.u-textPreLine {
  white-space: pre-line; }

.u-textPreWrap {
  white-space: pre-wrap; }

.u-textTruncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal; }

.u-hidden {
  overflow: hidden; }

.u-scroll {
  overflow: scroll; }

.u-auto {
  overflow: auto; }

.clearfix:before, .clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.top-0 {
  top: 0; }

.right-0 {
  right: 0; }

.bottom-0 {
  bottom: 0; }

.left-0 {
  left: 0; }

.nowrap {
  white-space: nowrap; }

.u-listUnstyled {
  margin: 0;
  padding: 0;
  list-style: none outside none; }

.u-alignBaseline {
  vertical-align: baseline; }

.u-alignBottom {
  vertical-align: bottom; }

.u-alignMiddle {
  vertical-align: middle; }

.u-alignTop {
  vertical-align: top; }

.u-textCenter {
  text-align: center; }

.u-tableCell {
  display: table-cell; }

.u-alignMiddle {
  vertical-align: middle; }

.u-fill, .u-fillHeight {
  height: 100%; }

.u-fill, .u-fillWidth {
  width: 100%; }

.u-table {
  display: table; }

.u-tableCell {
  display: table-cell; }

.u-tableRow {
  display: table-row; }

.u-inline {
  display: inline; }

.u-block {
  display: block; }

.u-invisible {
  visibility: hidden; }

.u-noneBlock {
  display: none; }

.u-inlineBlock {
  display: inline-block;
  max-width: 100%; }

.u-floatLeft {
  float: left; }

.u-floatRight {
  float: right; }

.u-blockCenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.u-posAbsolute {
  position: absolute; }

.u-posFixed {
  position: fixed; }

.u-posRelative {
  position: relative; }

.u-posStatic {
  position: static; }

.u-posAbsoluteCenter {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0; }

.u-transCenter {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0); }

.u-transCenterV, .slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0); }

.u-transCenterH {
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0, 0); }

.u-transFlipH {
  transform: scaleX(-1);
  filter: FlipH; }

.u-imgResponsive {
  display: block;
  max-width: 100%;
  height: auto; }

.u-fontSize16 {
  font-size: em(16); }

.u-colorGray {
  color: #484848; }

.u-font-size-0 {
  font-size: 0px; }

.u-borderRightGray {
  border-right: 1px solid #b9b9b9; }

.u-borderGrey {
  border: 2px solid #b9b9b9; }

/* Scroll
------------------------------------
*/
.scrollable {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

/* Overides
------------------------------------
*/
.no-padding {
  padding: 0px; }

.no-margin {
  margin: 0px; }

.no-overflow {
  overflow: hidden; }

.auto-overflow {
  overflow: auto; }

.center-margin {
  margin-left: auto;
  margin-right: auto; }

.inherit-size {
  width: inherit;
  height: inherit; }

.inherit-height {
  height: inherit; }

.image-responsive-height {
  width: 100%; }

.image-responsive-width {
  height: 100%; }

.u-overlayer {
  position: absolute;
  display: block;
  z-index: 21; }

.u-overlayer.fullwidth {
  width: 100%; }

.u-overlayer-wrapper {
  position: relative;
  display: block;
  z-index: 10; }

.overlay-fixed {
  position: fixed;
  top: auto; }

.u-top-left {
  position: absolute;
  top: 0;
  left: 0; }

.u-top-right {
  position: absolute;
  top: 1px;
  right: 0; }

.u-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0; }

.u-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0; }

.pull-bottom {
  position: absolute;
  bottom: 0; }

.pull-up {
  position: absolute;
  top: 0; }

.cursor {
  cursor: pointer; }

.scroll-x-hidden {
  overflow-x: hidden; }

/* Generic Padding Helpers
------------------------------------
*/
.p-t-5 {
  padding-top: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.padding-5 {
  padding: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.padding-10 {
  padding: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.padding-15 {
  padding: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-r-20 {
  padding-right: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.padding-20 {
  padding: 20px; }

.p-t-25 {
  padding-top: 25px; }

.p-r-25 {
  padding-right: 25px; }

.p-l-25 {
  padding-left: 25px; }

.p-b-25 {
  padding-bottom: 25px; }

.padding-25 {
  padding: 25px; }

.p-t-30 {
  padding-top: 30px; }

.p-r-30 {
  padding-right: 30px; }

.p-l-30 {
  padding-left: 30px; }

.p-b-30 {
  padding-bottom: 30px; }

.padding-30 {
  padding: 30px; }

.p-t-35 {
  padding-top: 35px; }

.p-r-35 {
  padding-right: 35px; }

.p-l-35 {
  padding-left: 35px; }

.p-b-35 {
  padding-bottom: 35px; }

.padding-35 {
  padding: 35px; }

.p-t-40 {
  padding-top: 40px; }

.p-r-40 {
  padding-right: 40px; }

.p-l-40 {
  padding-left: 40px; }

.p-b-40 {
  padding-bottom: 40px; }

.padding-40 {
  padding: 40px; }

.p-t-45 {
  padding-top: 45px; }

.p-r-45 {
  padding-right: 45px; }

.p-l-45 {
  padding-left: 45px; }

.p-b-45 {
  padding-bottom: 45px; }

.padding-45 {
  padding: 45px; }

.p-t-50 {
  padding-top: 50px; }

.p-r-50 {
  padding-right: 50px; }

.p-l-50 {
  padding-left: 50px; }

.p-b-50 {
  padding-bottom: 50px; }

.padding-50 {
  padding: 50px; }

.p-t-55 {
  padding-top: 55px; }

.p-r-55 {
  padding-right: 55px; }

.p-l-55 {
  padding-left: 55px; }

.p-b-55 {
  padding-bottom: 55px; }

.padding-55 {
  padding: 55px; }

.p-t-60 {
  padding-top: 60px; }

.p-r-60 {
  padding-right: 60px; }

.p-l-60 {
  padding-left: 60px; }

.p-b-60 {
  padding-bottom: 60px; }

.padding-60 {
  padding: 60px; }

.p-t-65 {
  padding-top: 65px; }

.p-r-65 {
  padding-right: 65px; }

.p-l-65 {
  padding-left: 65px; }

.p-b-65 {
  padding-bottom: 65px; }

.padding-65 {
  padding: 65px; }

.p-t-70 {
  padding-top: 70px; }

.p-r-70 {
  padding-right: 70px; }

.p-l-70 {
  padding-left: 70px; }

.p-b-70 {
  padding-bottom: 70px; }

.padding-70 {
  padding: 70px; }

.p-t-75 {
  padding-top: 75px; }

.p-r-75 {
  padding-right: 75px; }

.p-l-75 {
  padding-left: 75px; }

.p-b-75 {
  padding-bottom: 75px; }

.padding-75 {
  padding: 75px; }

.p-t-80 {
  padding-top: 80px; }

.p-r-80 {
  padding-right: 80px; }

.p-l-80 {
  padding-left: 80px; }

.p-b-80 {
  padding-bottom: 80px; }

.padding-80 {
  padding: 80px; }

.p-t-85 {
  padding-top: 85px; }

.p-r-85 {
  padding-right: 85px; }

.p-l-85 {
  padding-left: 85px; }

.p-b-85 {
  padding-bottom: 85px; }

.padding-85 {
  padding: 85px; }

.p-t-90 {
  padding-top: 90px; }

.p-r-90 {
  padding-right: 90px; }

.p-l-90 {
  padding-left: 90px; }

.p-b-90 {
  padding-bottom: 90px; }

.padding-90 {
  padding: 90px; }

.p-t-95 {
  padding-top: 95px; }

.p-r-95 {
  padding-right: 95px; }

.p-l-95 {
  padding-left: 95px; }

.p-b-95 {
  padding-bottom: 95px; }

.padding-95 {
  padding: 95px; }

.p-t-100 {
  padding-top: 100px; }

.p-r-100 {
  padding-right: 100px; }

.p-l-100 {
  padding-left: 100px; }

.p-b-100 {
  padding-bottom: 100px; }

.padding-100 {
  padding: 100px; }

/* Generic Margin Helpers
------------------------------------
 */
.m-t-0 {
  margin-top: 0px; }

.m-r-0 {
  margin-right: 0px; }

.m-l-0 {
  margin-left: 0px; }

.m-b-0 {
  margin-bottom: 0px; }

.m-t-5 {
  margin-top: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-r-20 {
  margin-right: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-t-25 {
  margin-top: 25px; }

.m-r-25 {
  margin-right: 25px; }

.m-l-25 {
  margin-left: 25px; }

.m-b-25 {
  margin-bottom: 25px; }

.m-t-30 {
  margin-top: 30px; }

.m-r-30 {
  margin-right: 30px; }

.m-l-30 {
  margin-left: 30px; }

.m-b-30 {
  margin-bottom: 30px; }

.m-t-35 {
  margin-top: 35px; }

.m-r-35 {
  margin-right: 35px; }

.m-l-35 {
  margin-left: 35px; }

.m-b-35 {
  margin-bottom: 35px; }

.m-t-40 {
  margin-top: 40px; }

.m-r-40 {
  margin-right: 40px; }

.m-l-40 {
  margin-left: 40px; }

.m-b-40 {
  margin-bottom: 40px; }

.m-t-45 {
  margin-top: 45px; }

.m-r-45 {
  margin-right: 45px; }

.m-l-45 {
  margin-left: 45px; }

.m-b-45 {
  margin-bottom: 45px; }

.m-t-50 {
  margin-top: 50px; }

.m-r-50 {
  margin-right: 50px; }

.m-l-50 {
  margin-left: 50px; }

.m-b-50 {
  margin-bottom: 50px; }

.m-t-55 {
  margin-top: 55px; }

.m-r-55 {
  margin-right: 55px; }

.m-l-55 {
  margin-left: 55px; }

.m-b-55 {
  margin-bottom: 55px; }

.m-t-60 {
  margin-top: 60px; }

.m-r-60 {
  margin-right: 60px; }

.m-l-60 {
  margin-left: 60px; }

.m-b-60 {
  margin-bottom: 60px; }

.m-t-65 {
  margin-top: 65px; }

.m-r-65 {
  margin-right: 65px; }

.m-l-65 {
  margin-left: 65px; }

.m-b-65 {
  margin-bottom: 65px; }

.m-t-70 {
  margin-top: 70px; }

.m-r-70 {
  margin-right: 70px; }

.m-l-70 {
  margin-left: 70px; }

.m-b-70 {
  margin-bottom: 70px; }

.m-t-75 {
  margin-top: 75px; }

.m-r-75 {
  margin-right: 75px; }

.m-l-75 {
  margin-left: 75px; }

.m-b-75 {
  margin-bottom: 75px; }

.m-t-80 {
  margin-top: 80px; }

.m-r-80 {
  margin-right: 80px; }

.m-l-80 {
  margin-left: 80px; }

.m-b-80 {
  margin-bottom: 80px; }

.m-t-85 {
  margin-top: 85px; }

.m-r-85 {
  margin-right: 85px; }

.m-l-85 {
  margin-left: 85px; }

.m-b-85 {
  margin-bottom: 85px; }

.m-t-90 {
  margin-top: 90px; }

.m-r-90 {
  margin-right: 90px; }

.m-l-90 {
  margin-left: 90px; }

.m-b-90 {
  margin-bottom: 90px; }

.m-t-95 {
  margin-top: 95px; }

.m-r-95 {
  margin-right: 95px; }

.m-l-95 {
  margin-left: 95px; }

.m-b-95 {
  margin-bottom: 95px; }

.m-t-100 {
  margin-top: 100px; }

.m-r-100 {
  margin-right: 100px; }

.m-l-100 {
  margin-left: 100px; }

.m-b-100 {
  margin-bottom: 100px; }

.full-height {
  height: 100%; }

.u-full-height {
  height: 100%; }

.full-width {
  width: 100%; }

.u-full-width {
  width: 100%; }

.hide {
  display: none; }

.inline {
  display: inline-block; }

.block {
  display: block; }

.b-blank {
  border-color: #000; }

/* Border Helpers
------------------------------------
*/
.b-a,
.b-r,
.b-l,
.b-t,
.b-b {
  border-style: solid;
  border-width: 0; }

.b-r {
  border-right-width: 1px; }

.b-l {
  border-left-width: 1px; }

.b-t {
  border-top-width: 1px; }

.b-b {
  border-bottom-width: 1px; }

.b-a {
  border-width: 1px; }

.b-dashed {
  border-style: dashed; }

.b-thick {
  border-width: 2px; }

.b-transparent {
  border-color: rgba(0, 0, 0, 0.4); }

.b-transparent-white {
  border-color: rgba(255, 255, 255, 0.44); }

.b-white {
  border-color: #fff; }

.b-primary {
  border-color: #6d5cae; }

.b-complete {
  border-color: #6d5cae; }

.b-success {
  border-color: #10cfbd; }

.b-info {
  border-color: #3b4752; }

.b-danger {
  border-color: #f55753; }

.b-warning {
  border-color: #f8d053; }

/* Border Radius
------------------------------------
*/
.b-rad-sm {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px; }

.b-rad-md {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px; }

.b-rad-lg {
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px; }

.no-border {
  border: none; }

/** Profile Wrappers **/
.profile-img-wrapper {
  float: left;
  border-radius: 100px 100px 100px 100px;
  display: inline-block;
  height: 35px;
  overflow: hidden;
  width: 35px;
  -webkit-flex: 1;
  -moz-flex: 1;
  flex: 1; }

.profile-img-wrapper.big {
  height: 68px;
  width: 68px; }

.profile-img-wrapper.with-left-space-custom {
  margin-left: 7px; }

.relative {
  position: relative; }

/* Demo Purposes */
.error-number {
  font-family: 'Montserrat';
  font-size: 90px;
  line-height: 90px; }

.visible-xlg {
  display: none; }

.hidden-xlg {
  display: block; }

.sm-gutter .row > [class^="col-"],
.sm-gutter .row > [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px; }

.sm-gutter .row {
  margin-left: -5px;
  margin-right: -5px; }

.v-align-bottom {
  vertical-align: bottom; }

.v-align-top {
  vertical-align: top; }

.v-align-middle {
  vertical-align: middle; }

/* vertical alignment styles */
.col-top {
  vertical-align: top; }

.col-middle {
  vertical-align: middle; }

.col-bottom {
  vertical-align: bottom; }

/* columns of same height styles
------------------------------------
*/
.u-container-xs-height {
  display: table;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; }

/*------------------------------------------------------------------
[29. Print]
*/
@media print {
  .header,
  .m-sidebar,
  .quickview-wrapper,
  .overlay {
    display: none; }
  .page-container {
    padding-left: 0; }
  .page-content-wrapper .content {
    border-top: 0; }
  .page-content-wrapper .content .panel {
    border: none; }
  .page-content-wrapper .content .panel .panel-body {
    padding: 0; }
  [class^='padding-'],
  [class*='padding-'],
  .table tbody tr td {
    padding: 10px; } }

/* Gradients
------------------------------------
*/
.u-gradient-black {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 85%); }

.u-z-1 {
  z-index: 1; }

.u-z-2 {
  z-index: 2; }

.u-z-3 {
  z-index: 3; }

.u-z-4 {
  z-index: 5; }

.u-z-5 {
  z-index: 5; }

.u-z-6 {
  z-index: 6; }

.german nav ul.navbar-nav li a {
  font-size: 13px; }
  @media (max-width: 991px) {
    .german nav ul.navbar-nav li a {
      font-size: 12px; } }

.german .category-block .category .h1 {
  line-height: 26px;
  font-size: 28px;
  margin-bottom: 20px;
  padding-top: 10px; }

.german .h1 {
  font-size: 28px; }
  @media (max-width: 1199px) {
    .german .h1 {
      line-height: 30px;
      font-size: 19px; } }

.german .category-block .hover .btn-cast {
  font-size: 15px; }

.german .video-info .container {
  max-width: 905px; }
  @media (max-width: 1199px) {
    .german .video-info .container {
      line-height: 30px; } }

@media (max-width: 1199px) {
  .german .video-info .h1 {
    line-height: 30px;
    font-size: 22px; } }

.german .video-info .video-text {
  line-height: 20px; }
  @media (max-width: 480px) {
    .german .video-info .video-text {
      line-height: 18px; } }

.german .icon-carleaseplay.video-btn {
  font-size: 30px; }
  @media (max-width: 1199px) {
    .german .icon-carleaseplay.video-btn {
      display: none; } }

.german footer .bottom-block .dealer-dashbord {
  padding-left: 14px;
  padding-right: 14px;
  font-size: 14px; }

.german .media .sub-nav .sub-nav-block .sub-nav-block-inner ul li a {
  font-size: 14px; }

.german .leadership .h1,
.german .history .h1 {
  font-size: 22px; }
  .german .leadership .h1 .icon-carleasedownload,
  .german .history .h1 .icon-carleasedownload {
    font-size: 28px; }
  .german .leadership .h1 .icon-carleaseplay.play,
  .german .history .h1 .icon-carleaseplay.play {
    font-size: 28px; }
  @media (max-width: 767px) {
    .german .leadership .h1,
    .german .history .h1 {
      line-height: 30px;
      height: 35px;
      font-size: 19px; } }

@media (max-width: 767px) {
  .german .media .content .h1 {
    line-height: 30px;
    height: 25px; } }

.german .main-slider .item__inner h2 {
  font-size: 38px; }
  @media (max-width: 767px) {
    .german .main-slider .item__inner h2 {
      font-size: 22px;
      line-height: 22px; } }

.german .main-slider .btn-cast {
  height: 32px;
  line-height: 34px;
  font-size: 18px; }

@media (max-width: 767px) {
  .german .main-slider .item__inner .slider__text {
    font-size: 14px; } }

.german .product-one-item .btn-block a i {
  padding-left: 0;
  padding-right: 0; }

@media (max-width: 400px) {
  .german .advantages_items .h1 {
    line-height: 26px;
    height: auto;
    padding-bottom: 10px; } }

.german .products .items .category-name,
.german .products .items .subcategory-name {
  height: auto;
  line-height: 35px;
  padding-top: 10px;
  padding-bottom: 10px; }

.german .products .items .subcategory-name {
  font-size: 25px; }

.german .category-block .category:hover .hover .category-text {
  height: 16.4em;
  font-size: 11px; }

textarea,
input[type="text"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 0;
  box-shadow: none; }

.main-container {
  min-height: -webkit-calc(100% - 480px);
  min-height: calc(100% - 480px); }

.h_iframe {
  position: relative; }

.h_iframe .ratio {
  display: block;
  width: 100%;
  height: auto; }

.h_iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

html {
  height: 100%;
  margin: 0;
  padding: 0; }

section > div {
  padding-bottom: 3%; }

.pl-0 {
  padding-left: 0; }

.pr-0 {
  padding-right: 0; }

body {
  background: #FBFBFB;
  font-family: 'Arimo', sans-serif;
  color: #000000;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Arimo', sans-serif;
  height: 100%;
  margin: 0;
  padding: 0; }
  body input, body textarea, body button, body a {
    font-family: 'Arimo', sans-serif;
    font-weight: 400; }
  body h1, body h2, body h3 {
    font-family: 'Arimo', sans-serif;
    font-weight: 700; }
  body a {
    position: relative;
    color: #ffffff;
    text-decoration: none;
    outline: none; }
    body a:hover {
      color: #00adf1; }
  body i {
    display: inline-block; }

*,
*:after,
*:before {
  box-sizing: border-box;
  padding: 0;
  margin: 0; }

.h1 {
  color: #00adf1;
  font-size: 30px;
  font-weight: 700; }

.h2,
.h3 {
  color: #555555;
  font-size: 18px;
  font-weight: 700; }

@media all and (max-width: 767px) {
  .h1 {
    font-size: 24px; }
  .h2,
  .h3 {
    font-size: 16px; } }

ul li {
  list-style: none; }

.bullets ul, .text ul {
  list-style: none;
  padding: 0;
  margin: 0; }

.bullets li, .text li {
  padding-left: 1.3em;
  position: relative; }

.bullets li:before, .text li:before {
  content: "• ";
  color: #00adf1;
  /* or whatever color you prefer */
  font-size: 30px;
  position: absolute;
  line-height: 16px;
  top: 7px;
  left: 0; }

.bullets.vertical li:before, .text.vertical li:before {
  top: 50%;
  transform: translate3d(0, -50%, 0); }

.upper {
  text-transform: uppercase; }

.img-responsive {
  max-width: 100%; }

.text-center {
  text-align: center; }

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5; }

.plus {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  vertical-align: top;
  margin-top: 4px; }
  .plus div {
    width: 20px;
    height: 2px;
    background: #00adf1;
    position: absolute;
    top: 10px; }
    .plus div.vertical {
      transition: 0.3s;
      transform: rotate(90deg); }
  .plus.active div {
    background: #ffffff; }
  .plus.active div.vertical {
    transform: rotate(0deg); }

.btn-cast {
  border-radius: 2px;
  border-width: 1px;
  border-color: #00adf1;
  border-style: solid;
  background-color: #00adf1;
  padding-left: 30px;
  padding-right: 30px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  transition: 0.3s;
  box-sizing: content-box;
  cursor: pointer; }
  .btn-cast:hover {
    background-color: #f6f6f6;
    color: #00adf1; }

input:not([type="checkbox"]):not([type="submit"]), textarea, select {
  border-radius: 2px;
  border-width: 1px;
  border-color: #e0e0e0;
  border-style: solid;
  background-color: #f6f6f6;
  padding-left: 15px;
  height: 43px;
  line-height: 43px;
  color: #000000;
  width: 100%;
  font-size: 18px;
  margin-top: 8px;
  margin-bottom: 8px;
  transition: 0.5s; }
  input:not([type="checkbox"]):not([type="submit"])::placeholder, textarea::placeholder, select::placeholder {
    color: #555555;
    font-size: 18px;
    font-weight: 400; }
  input:not([type="checkbox"]):not([type="submit"]):hover, input:not([type="checkbox"]):not([type="submit"]):focus, textarea:hover, textarea:focus, select:hover, select:focus {
    border-radius: 2px;
    border-width: 1px;
    border-color: #00adf1;
    border-style: solid;
    background-color: #f6f6f6; }

textarea {
  overflow: hidden;
  resize: none; }

.img-block {
  height: 230px;
  position: relative; }
  .img-block .date {
    position: absolute;
    left: 0;
    top: 20px;
    padding: 4px 20px;
    background: #ffffff;
    color: #555555;
    z-index: 9; }

.advantages_items.gallery-wrap {
  width: 100%; }

.advantages_items .h1 {
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 60px;
  height: 60px;
  border-bottom: 1px solid #e0e0e0; }

.advantages_items .info {
  margin: 20px 0; }

.gallery-wrap {
  display: inline-block; }
  .gallery-wrap a {
    display: inline-block;
    position: relative;
    overflow: hidden;
    text-align: center;
    cursor: pointer;
    margin: 0;
    margin-bottom: 20px; }
  .gallery-wrap a img {
    position: relative;
    display: block;
    max-width: 100%;
    opacity: 1; }
  .gallery-wrap a p {
    height: 40px;
    width: 60px;
    line-height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -30px;
    font-weight: 400;
    font-size: 20px;
    z-index: 2;
    color: #ffffff; }
  .gallery-wrap a.effect-ruby {
    background-color: #00adf1; }
  .gallery-wrap a.effect-ruby img {
    opacity: 1;
    transition: opacity 0.35s, transform 0.35s;
    transform: scale(1.15); }
  .gallery-wrap a.effect-ruby:hover img {
    opacity: 0.5;
    transform: scale(1); }
  .gallery-wrap a.effect-ruby p {
    opacity: 0;
    transition: opacity 0.35s, transform 0.35s;
    transform: translate3d(0, 40px, 0) scale(1.5); }
  .gallery-wrap a.effect-ruby:hover h2 {
    transform: translate3d(0, 0, 0); }
  .gallery-wrap a.effect-ruby:hover p {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }

.custom-dropdown {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%; }

.custom-dropdown select {
  color: #555555;
  cursor: pointer;
  padding-right: 2.5em;
  border: 1px solid #e0e0e0;
  text-indent: 0.01px;
  text-overflow: '';
  -webkit-appearance: button;
  /* hide default arrow in chrome OSX */ }

.custom-dropdown::before,
.custom-dropdown::after {
  content: "";
  position: absolute;
  pointer-events: none; }

.custom-dropdown::after {
  /*  Custom dropdown arrow */
  content: "\25BC";
  height: 1em;
  font-size: 21px;
  line-height: 1;
  right: 10px;
  top: 50%;
  margin-top: -10px;
  transition: 0.4s; }

.custom-dropdown::before {
  /*  Custom dropdown arrow cover */
  width: 41px;
  right: 1px;
  height: 41px;
  top: 9px;
  bottom: 0;
  transition: 0.4s; }

.custom-dropdown.hover select {
  border: 1px solid #00adf1; }

.custom-dropdown::before {
  background-color: #e0e0e0; }

.custom-dropdown.hover::before {
  background-color: #00adf1; }

.custom-dropdown.focus::before {
  background-color: #00adf1; }

.custom-dropdown::after {
  color: #00adf1; }

.custom-dropdown.hover::after,
.custom-dropdown.focus::after {
  color: #ffffff; }

input[type=checkbox] {
  visibility: hidden; }

/* SQUARED THREE */
.squaredThree {
  width: 20px !important;
  display: inline-block;
  position: relative;
  margin-left: 10px; }

.squaredThree label {
  cursor: pointer;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 11px;
  left: 0px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  background: #ffffff;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#222', endColorstr='#45484d', GradientType=0); }

.squaredThree label:after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  content: '';
  position: absolute;
  width: 12px;
  height: 8px;
  background: transparent;
  top: 4px;
  left: 4px;
  border: 2px solid #00adf1;
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.squaredThree label:hover::after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
  opacity: 0.3; }

.squaredThree input:checked + label:after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1; }

/* ----------------------------------------------------------------------------------------------------------
---------------------------------Header -------------------------------------------------------
---------------------------------------------------------------------------------------------------------- */
.redirect-link {
  display: inline-block;
  position: absolute;
  top: 0;
  z-index: 9; }
  .redirect-link:first-child {
    right: 140px; }
  .redirect-link:nth-child(2) {
    right: 95px; }
  .redirect-link:nth-child(3) {
    right: 50px; }

nav .navbar-header .navbar-brand {
  padding: 10px 20px 0px 0px; }

nav ul.navbar-nav {
  margin-top: 15px; }
  nav ul.navbar-nav li.active a {
    border-top: 1px solid #00adf1;
    border-bottom: 1px solid #00adf1;
    padding: 10px 0;
    margin: 10px 6px; }
  nav ul.navbar-nav li a {
    font-size: 14px;
    text-transform: uppercase;
    transition: 0.3s ease 0.2s;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    padding: 20px 0;
    margin: 0 6px; }
    nav ul.navbar-nav li a:hover, nav ul.navbar-nav li a.active {
      border-top: 1px solid #00adf1;
      border-bottom: 1px solid #00adf1;
      padding: 10px 0;
      margin: 10px 6px; }

.menu-icon {
  position: relative;
  width: 54px;
  height: 54px;
  border-radius: 50%; }

.menu-icon span {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 22px;
  height: 2px;
  background-color: #00adf1;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%); }

.menu-icon span::before, .menu-icon span::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 22px;
  height: 2px;
  background-color: #00adf1;
  transform: translateY(-6px);
  transition: transform 0.5s; }

.menu-icon span::after {
  transform: translateY(6px); }

.menu-icon:hover span::before {
  -webkit-transform: translateY(-8px);
  -ms-transform: translateY(-8px);
  transform: translateY(-8px); }

.menu-icon:hover span::after {
  -webkit-transform: translateY(8px);
  -ms-transform: translateY(8px);
  transform: translateY(8px); }

.menu-icon-svg span {
  transition: background 0.5s steps(1, end); }

.menu-icon-svg span::before, .menu-icon-svg span::after {
  -webkit-transform-origin: 100% 50%;
  -moz-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%; }

.menu-icon-svg svg {
  opacity: 0;
  fill: transparent;
  stroke-width: 2px;
  stroke: #00adf1;
  stroke-dasharray: 22 230;
  transition: stroke-dasharray 0.5s, stroke-dashoffset 0.5s, opacity 0.5s steps(1, end); }

.menu-icon-svg.is-opened span {
  transition: background 0.5s steps(1, start);
  background-color: transparent; }

.menu-icon-svg.is-opened span::before {
  transform: translateX(-3px) translateY(-8px) rotate(-45deg); }

.menu-icon-svg.is-opened span::after {
  transform: translateX(-3px) translateY(8px) rotate(45deg); }

.menu-icon-svg.is-opened svg {
  opacity: 1;
  transition: opacity 0.5s steps(1, start);
  -webkit-animation: pathanimation 0.5s linear;
  -o-animation: pathanimation 0.5s linear;
  animation: pathanimation 0.5s linear;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards; }

.menu-icon-svg.is-closed svg {
  -webkit-animation: pathanimation-out 0.5s linear;
  -o-animation: pathanimation-out 0.5s linear;
  animation: pathanimation-out 0.5s linear; }

@-webkit-keyframes pathanimation {
  40% {
    stroke-dasharray: 22 230; }
  60% {
    stroke-dashoffset: -65; }
  100% {
    stroke-dashoffset: -65;
    stroke-dasharray: 160; } }

@keyframes pathanimation {
  40% {
    stroke-dasharray: 22 230; }
  60% {
    stroke-dashoffset: -65; }
  100% {
    stroke-dashoffset: -65;
    stroke-dasharray: 160; } }

@-webkit-keyframes pathanimation-out {
  100% {
    stroke-dashoffset: 0; }
  60% {
    stroke-dasharray: 22 230; }
  40% {
    stroke-dashoffset: -65; }
  0% {
    stroke-dashoffset: -65;
    stroke-dasharray: 160; } }

@keyframes pathanimation-out {
  100% {
    stroke-dashoffset: 0; }
  60% {
    stroke-dasharray: 22 230; }
  40% {
    stroke-dashoffset: -65; }
  0% {
    stroke-dashoffset: -65;
    stroke-dasharray: 160; } }

@media all and (max-width: 1199px) {
  nav ul.navbar-nav {
    margin-top: 0px; }
    nav ul.navbar-nav li:first-child a {
      margin-left: 0px; }
  .navbar-header {
    height: 90px; } }

@media all and (max-width: 767px) {
  .navbar-collapse.in {
    overflow-y: inherit; }
  nav .container {
    padding-left: 0px;
    padding-right: 0px; }
  nav .navbar-header .navbar-brand {
    padding: 10px 0px 0px 10px; }
    nav .navbar-header .navbar-brand img {
      max-width: 300px;
      margin-bottom: 15px; }
  nav .navbar-header .navbar-toggle {
    margin-top: 0px; }
  nav ul.navbar-nav {
    margin-top: 15px; }
    nav ul.navbar-nav li {
      background: rgba(224, 224, 224, 0.7); }
      nav ul.navbar-nav li.active {
        background: #00adf1; }
        nav ul.navbar-nav li.active a {
          border-top: 1px solid transparent;
          border-bottom: 1px solid #00adf1;
          background-color: #00adf1;
          padding: 10px 0;
          margin: 0px 0px;
          color: #ffffff !important; }
      nav ul.navbar-nav li a {
        border-top: 1px solid transparent;
        border-bottom: 1px solid #e0e0e0;
        padding: 10px 0;
        margin: 0px 0px;
        text-align: center; }
        nav ul.navbar-nav li a:hover, nav ul.navbar-nav li a.active {
          border-top: 1px solid transparent;
          background-color: #00adf1;
          border-bottom: 1px solid #00adf1;
          padding: 10px 0;
          margin: 0px 0px;
          color: #ffffff !important; }
      nav ul.navbar-nav li:hover {
        background: #00adf1; }
        nav ul.navbar-nav li:hover a {
          color: #ffffff; }
      nav ul.navbar-nav li.redirect-min {
        background: #8e8e8e; }
        nav ul.navbar-nav li.redirect-min a {
          color: #ffffff; }
        nav ul.navbar-nav li.redirect-min i {
          vertical-align: bottom;
          margin-right: 10px; }
        nav ul.navbar-nav li.redirect-min:hover {
          background: rgba(142, 142, 142, 0.8); }
          nav ul.navbar-nav li.redirect-min:hover a {
            border-bottom: 1px solid transparent; } }

@media all and (max-width: 480px) {
  nav.navbar {
    min-height: 80px; }
  nav .navbar-header .navbar-toggle {
    margin-bottom: 0; }
  nav .navbar-header .navbar-brand {
    height: 70px;
    padding: 10px 0px 0px 10px; }
    nav .navbar-header .navbar-brand img {
      width: 190px; } }

/* ----------------------------------------------------------------------------------------------------------
---------------------------------Home page -------------------------------------------------------
---------------------------------------------------------------------------------------------------------- */
@media all and (max-width: 1090px) {
  .slide__arumatic {
    background-position-x: -500px !important; } }

@media all and (max-width: 840px) {
  .slide__arumatic {
    background-position-x: -650px !important; } }

@media all and (max-width: 768px) {
  .slide__arumatic {
    background-image: url("/resources/images/tablet-slide-v2-min.jpg") !important;
    background-position-x: center !important; }
  .slide__arumatic-en {
    background-image: url("/resources/images/tablet-slide-v2-min.jpg") !important; }
  .slide__arumatic-de {
    background-image: url("/resources/images/tablet-slide-v2-de-min.jpg") !important; } }

.da-slider {
  width: 100%;
  min-width: 520px;
  height: 400px;
  position: relative;
  margin: 0px auto;
  overflow: hidden;
  transition: background-position 1s ease-out 0.3s; }

.da-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  text-align: left; }
  .da-slide .btn-cast {
    margin-left: 10px; }

.da-slide-current {
  z-index: 1000; }

.da-slider-fb .da-slide {
  left: 100%; }

.da-slider-fb .da-slide.da-slide-current {
  position: absolute;
  opacity: 0;
  left: 110%; }

.da-slide .bg {
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0;
  left: 110%;
  transition: 0.8s; }

.da-slide-current .bg {
  left: 0;
  opacity: 1; }

.da-slide h2,
.da-slide .p,
.da-slide .da-link,
.da-slide .da-img {
  position: absolute;
  opacity: 0;
  left: 110%; }

.da-slider-fb .da-slide h2,
.da-slider-fb .da-slide .p {
  left: 50%;
  opacity: 1; }

.da-slider-fb .da-slide .da-link {
  left: 55%;
  opacity: 1; }

.da-slider-fb .da-slide .da-img {
  left: 110%;
  opacity: 1; }

.da-slide h2 {
  color: #00adf1;
  font-size: 47px;
  /* Приближение из-за подстановки шрифтов */
  font-weight: 700;
  line-height: 47px;
  /* Приближение из-за подстановки шрифтов */
  text-align: left;
  width: 50%;
  top: 60px;
  white-space: nowrap;
  z-index: 10; }

.da-slide .p {
  width: 45%;
  top: 105px;
  color: #000000;
  font-size: 30px;
  /* Приближение из-за подстановки шрифтов */
  font-weight: 700;
  line-height: 40px;
  /* Приближение из-за подстановки шрифтов */
  text-align: left; }
  .da-slide .p div {
    margin-top: 5px;
    font-size: 17px;
    /* Приближение из-за подстановки шрифтов */
    line-height: 21px;
    height: 84px;
    font-weight: 400;
    text-align: left;
    display: block;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; }

.da-slide .da-img {
  text-align: center;
  width: 40%;
  top: 20px;
  height: 256px;
  line-height: 256px;
  line-height: 320px;
  left: 110%;
  /*60%*/ }
  .da-slide .da-img img {
    max-width: 100%;
    vertical-align: middle; }

.da-slide .da-link {
  top: 260px;
  text-align: center; }

.da-dots {
  width: 50%;
  margin-left: 50%;
  position: absolute;
  text-align: left;
  left: 0px;
  bottom: 60px;
  z-index: 2000;
  -moz-user-select: none;
  -webkit-user-select: none; }

.da-dots span {
  display: inline-block;
  position: relative;
  border-radius: 2px;
  border-width: 1px;
  border-color: #00adf1;
  border-style: solid;
  width: 56px;
  height: 5px;
  cursor: pointer;
  margin: 3px;
  box-sizing: border-box; }

.da-dots span.da-dots-current:after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 2px;
  background-color: #00adf1;
  width: 55px;
  height: 3px;
  transition: 0.5s ease; }

.da-arrows {
  -moz-user-select: none;
  -webkit-user-select: none; }

.da-arrows span {
  position: absolute;
  top: 50%;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #e4b42d;
  cursor: pointer;
  z-index: 2000;
  opacity: 0;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1) inset, 1px 1px 1px rgba(255, 255, 255, 0.1);
  transition: opacity 0.4s ease-in-out-out 0.2s; }

.da-slider:hover .da-arrows span {
  opacity: 1; }

.da-arrows span:after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  top: 5px;
  left: 5px;
  background: transparent url(../images/arrows.png) no-repeat top left;
  border-radius: 50%;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); }

.da-arrows span:hover:after {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3); }

.da-arrows span:active:after {
  box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.1); }

.da-arrows span.da-arrows-next:after {
  background-position: top right; }

.da-arrows span.da-arrows-prev {
  left: 15px; }

.da-arrows span.da-arrows-next {
  right: 15px; }

.da-slide-current h2,
.da-slide-current .p {
  left: 50%;
  opacity: 1; }

.da-slide-current .da-link {
  left: 55%;
  opacity: 1; }

.da-slide-current .da-img {
  left: 10%;
  opacity: 1; }

.da-slide-fromright h2 {
  -webkit-animation: fromRightAnim1 0.6s ease-in-out 0.8s both;
  -o-animation: fromRightAnim1 0.6s ease-in-out 0.8s both;
  animation: fromRightAnim1 0.6s ease-in-out 0.8s both; }

.da-slide-fromright .p {
  -webkit-animation: fromRightAnim2 0.6s ease-in-out 0.8s both;
  -o-animation: fromRightAnim2 0.6s ease-in-out 0.8s both;
  animation: fromRightAnim2 0.6s ease-in-out 0.8s both; }

.da-slide-fromright .da-link {
  -webkit-animation: fromRightAnim3 0.4s ease-in-out 1.2s both;
  -o-animation: fromRightAnim3 0.4s ease-in-out 1.2s both;
  animation: fromRightAnim3 0.4s ease-in-out 1.2s both; }

.da-slide-fromright .da-img {
  -webkit-animation: fromRightAnim4 0.6s ease-in-out 0.8s both;
  -o-animation: fromRightAnim4 0.6s ease-in-out 0.8s both;
  animation: fromRightAnim4 0.6s ease-in-out 0.8s both; }

.da-slide-fromright .bg {
  -webkit-animation: fromRightAnim5 0.6s ease-in-out 0.8s both;
  -o-animation: fromRightAnim5 0.6s ease-in-out 0.8s both;
  animation: fromRightAnim5 0.6s ease-in-out 0.8s both; }

.da-slide-fromleft h2 {
  -webkit-animation: fromLeftAnim1 0.6s ease-in-out 0.6s both;
  -o-animation: fromLeftAnim1 0.6s ease-in-out 0.6s both;
  animation: fromLeftAnim1 0.6s ease-in-out 0.6s both; }

.da-slide-fromleft .p {
  -webkit-animation: fromLeftAnim2 0.6s ease-in-out 0.6s both;
  -o-animation: fromLeftAnim2 0.6s ease-in-out 0.6s both;
  animation: fromLeftAnim2 0.6s ease-in-out 0.6s both; }

.da-slide-fromleft .da-link {
  -webkit-animation: fromLeftAnim3 0.4s ease-in-out 1.2s both;
  -o-animation: fromLeftAnim3 0.4s ease-in-out 1.2s both;
  animation: fromLeftAnim3 0.4s ease-in-out 1.2s both; }

.da-slide-fromleft .da-img {
  -webkit-animation: fromLeftAnim4 0.6s ease-in-out 0.6s both;
  -o-animation: fromLeftAnim4 0.6s ease-in-out 0.6s both;
  animation: fromLeftAnim4 0.6s ease-in-out 0.6s both; }

.da-slide-fromleft .bg {
  -webkit-animation: fromLeftAnim5 0.6s ease-in-out 0.6s both;
  -o-animation: fromLeftAnim5 0.6s ease-in-out 0.6s both;
  animation: fromLeftAnim5 0.6s ease-in-out 0.6s both; }

.da-slide-toright h2 {
  -webkit-animation: toRightAnim1 0.6s ease-in-out 0.6s both;
  -o-animation: toRightAnim1 0.6s ease-in-out 0.6s both;
  animation: toRightAnim1 0.6s ease-in-out 0.6s both; }

.da-slide-toright .p {
  -webkit-animation: toRightAnim2 0.6s ease-in-out 0.3s both;
  -o-animation: toRightAnim2 0.6s ease-in-out 0.3s both;
  animation: toRightAnim2 0.6s ease-in-out 0.3s both; }

.da-slide-toright .da-link {
  -webkit-animation: toRightAnim3 0.4s ease-in-out both;
  -o-animation: toRightAnim3 0.4s ease-in-out both;
  animation: toRightAnim3 0.4s ease-in-out both; }

.da-slide-toright .da-img {
  -webkit-animation: toRightAnim4 0.6s ease-in-out both;
  -o-animation: toRightAnim4 0.6s ease-in-out both;
  animation: toRightAnim4 0.6s ease-in-out both; }

.da-slide-toright .bg {
  -webkit-animation: toRightAnim5 0.6s ease-in-out 0.3s both;
  -o-animation: toRightAnim5 0.6s ease-in-out 0.3s both;
  animation: toRightAnim5 0.6s ease-in-out 0.3s both; }

.da-slide-toleft h2 {
  -webkit-animation: toLeftAnim1 0.6s ease-in-out both;
  -o-animation: toLeftAnim1 0.6s ease-in-out both;
  animation: toLeftAnim1 0.6s ease-in-out both; }

.da-slide-toleft .p {
  -webkit-animation: toLeftAnim2 0.6s ease-in-out 0.3s both;
  -o-animation: toLeftAnim2 0.6s ease-in-out 0.3s both;
  animation: toLeftAnim2 0.6s ease-in-out 0.3s both; }

.da-slide-toleft .da-link {
  -webkit-animation: toLeftAnim3 0.6s ease-in-out 0.6s both;
  -o-animation: toLeftAnim3 0.6s ease-in-out 0.6s both;
  animation: toLeftAnim3 0.6s ease-in-out 0.6s both; }

.da-slide-toleft .da-img {
  -webkit-animation: toLeftAnim4 0.6s ease-in-out 0.5s both;
  -o-animation: toLeftAnim4 0.6s ease-in-out 0.5s both;
  animation: toLeftAnim4 0.6s ease-in-out 0.5s both; }

.da-slide-toleft .bg {
  -webkit-animation: toLeftAnim5 0.6s ease-in-out 0.3s both;
  -o-animation: toLeftAnim5 0.6s ease-in-out 0.3s both;
  animation: toLeftAnim5 0.6s ease-in-out 0.3s both; }

@media all and (max-width: 1200px) {
  .da-slide h2 {
    font-size: 42px;
    line-height: 42px; } }

@media all and (max-width: 991px) {
  .da-slide .da-link {
    width: 50%;
    text-align: left; }
  .da-slide h2 {
    font-size: 32px;
    line-height: 32px; }
  .da-slide .p {
    top: 95px;
    font-size: 22px; }
  .da-slider-fb .da-slide .da-link,
  .da-slider-fb .da-slide .p,
  .da-slider-fb .da-slide h2 {
    left: 45%; }
  .da-slide-current .da-link,
  .da-slide-current .p,
  .da-slide-current h2 {
    left: 45%; }
  .da-slide-current .da-img {
    left: 5%; }
  .da-slide-fromright .da-link {
    -webkit-animation: fromRightAnim3Sm 0.4s ease-in-out 1.2s both;
    -o-animation: fromRightAnim3Sm 0.4s ease-in-out 1.2s both;
    animation: fromRightAnim3Sm 0.4s ease-in-out 1.2s both; }
  .da-slide-fromright .da-img {
    -webkit-animation: fromRightAnim4Sm 0.6s ease-in-out 0.8s both;
    -o-animation: fromRightAnim4Sm 0.6s ease-in-out 0.8s both;
    animation: fromRightAnim4Sm 0.6s ease-in-out 0.8s both; }
  .da-slide-fromright h2 {
    -webkit-animation: fromRightAnim1Sm 0.6s ease-in-out 0.8s both;
    -o-animation: fromRightAnim1Sm 0.6s ease-in-out 0.8s both;
    animation: fromRightAnim1Sm 0.6s ease-in-out 0.8s both; }
  .da-slide-fromright .p {
    -webkit-animation: fromRightAnim2Sm 0.6s ease-in-out 0.8s both;
    -o-animation: fromRightAnim2Sm 0.6s ease-in-out 0.8s both;
    animation: fromRightAnim2Sm 0.6s ease-in-out 0.8s both; }
  .da-slide-fromleft .da-link {
    -webkit-animation: fromLeftAnim3Sm 0.4s ease-in-out 1.2s both;
    -o-animation: fromLeftAnim3Sm 0.4s ease-in-out 1.2s both;
    animation: fromLeftAnim3Sm 0.4s ease-in-out 1.2s both; }
  .da-slide-fromleft .da-img {
    -webkit-animation: fromLeftAnim4Sm 0.6s ease-in-out 0.6s both;
    -o-animation: fromLeftAnim4Sm 0.6s ease-in-out 0.6s both;
    animation: fromLeftAnim4Sm 0.6s ease-in-out 0.6s both; }
  .da-slide-fromleft h2 {
    -webkit-animation: fromLeftAnim1Sm 0.6s ease-in-out 0.6s both;
    -o-animation: fromLeftAnim1Sm 0.6s ease-in-out 0.6s both;
    animation: fromLeftAnim1Sm 0.6s ease-in-out 0.6s both; }
  .da-slide-fromleft .p {
    -webkit-animation: fromLeftAnim2Sm 0.6s ease-in-out 0.6s both;
    -o-animation: fromLeftAnim2Sm 0.6s ease-in-out 0.6s both;
    animation: fromLeftAnim2Sm 0.6s ease-in-out 0.6s both; }
  .da-slide-toright .da-link {
    -webkit-animation: toRightAnim3Sm 0.4s ease-in-out both;
    -o-animation: toRightAnim3Sm 0.4s ease-in-out both;
    animation: toRightAnim3Sm 0.4s ease-in-out both; }
  .da-slide-toright .da-img {
    -webkit-animation: toRightAnim4Sm 0.6s ease-in-out both;
    -o-animation: toRightAnim4Sm 0.6s ease-in-out both;
    animation: toRightAnim4Sm 0.6s ease-in-out both; }
  .da-slide-toright h2 {
    -webkit-animation: toRightAnim1Sm 0.6s ease-in-out 0.6s both;
    -o-animation: toRightAnim1Sm 0.6s ease-in-out 0.6s both;
    animation: toRightAnim1Sm 0.6s ease-in-out 0.6s both; }
  .da-slide-toright .p {
    -webkit-animation: toRightAnim2Sm 0.6s ease-in-out 0.3s both;
    -o-animation: toRightAnim2Sm 0.6s ease-in-out 0.3s both;
    animation: toRightAnim2Sm 0.6s ease-in-out 0.3s both; }
  .da-slide-toleft .da-link {
    -webkit-animation: toLeftAnim3Sm 0.6s ease-in-out 0.6s both;
    -o-animation: toLeftAnim3Sm 0.6s ease-in-out 0.6s both;
    animation: toLeftAnim3Sm 0.6s ease-in-out 0.6s both; }
  .da-slide-toleft .da-img {
    -webkit-animation: toLeftAnim4Sm 0.6s ease-in-out 0.5s both;
    -o-animation: toLeftAnim4Sm 0.6s ease-in-out 0.5s both;
    animation: toLeftAnim4Sm 0.6s ease-in-out 0.5s both; }
  .da-slide-toleft h2 {
    -webkit-animation: toLeftAnim1Sm 0.6s ease-in-out both;
    -o-animation: toLeftAnim1Sm 0.6s ease-in-out both;
    animation: toLeftAnim1Sm 0.6s ease-in-out both; }
  .da-slide-toleft .p {
    -webkit-animation: toLeftAnim2Sm 0.6s ease-in-out 0.3s both;
    -o-animation: toLeftAnim2Sm 0.6s ease-in-out 0.3s both;
    animation: toLeftAnim2Sm 0.6s ease-in-out 0.3s both; } }

@media all and (max-width: 767px) {
  .da-slider {
    width: 100%;
    min-width: 280px;
    height: 600px;
    background: #e0e0e0; }
    .da-slider .da-dots {
      width: 100%;
      margin-left: 0;
      text-align: center;
      bottom: 20px; }
      .da-slider .da-dots span {
        display: inline-block;
        position: relative;
        border-radius: 50%;
        border-width: 1px;
        border-color: #00adf1;
        border-style: solid;
        width: 16px;
        height: 16px;
        cursor: pointer;
        margin: 3px;
        box-sizing: border-box; }
      .da-slider .da-dots span.da-dots-current:after {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #00adf1;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        transition: 0.5s ease; }
  .da-slide .da-img {
    width: 90%;
    top: 15px;
    text-align: center; }
    .da-slide .da-img .header {
      line-height: 40px;
      font-size: 24px;
      height: 40px;
      color: #00adf1;
      font-weight: 700; }
    .da-slide .da-img .category {
      line-height: 24px;
      height: 24px;
      font-size: 18px;
      font-weight: 700; }
    .da-slide .da-img .image {
      max-height: 300px; }
    .da-slide .da-img .text {
      font-size: 14px;
      line-height: 18px;
      max-height: 72px;
      overflow: hidden; }
    .da-slide .da-img .btn-block {
      height: 60px;
      margin-top: 20px;
      line-height: 60px; } }

@media all and (max-width: 480px) {
  .da-slide .da-img .image {
    height: 250px;
    line-height: 250px; }
    .da-slide .da-img .image img {
      max-height: 100%;
      max-width: 70%;
      vertical-align: middle; } }

.text-block {
  padding: 40px 20px 100px; }
  .text-block .h1 {
    margin-bottom: 20px; }
  @media all and (max-width: 767px) {
    .text-block {
      padding: 50px 20px; } }

.category-block {
  height: 270px;
  background: #8e8e8e; }
  .category-block .category {
    height: 570px;
    border-left: 2px solid #FBFBFB;
    text-align: center; }
    .category-block .category .sm-block {
      position: relative; }
    .category-block .category .top-block {
      position: absolute;
      width: 100%;
      z-index: 999; }
    .category-block .category .h1,
    .category-block .category .h2 {
      color: #ffffff; }
    .category-block .category .h1 {
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 36px;
      height: 72px;
      transition: All 0.7s linear; }
    .category-block .category i {
      margin-top: 10px;
      margin-bottom: 25px; }
    .category-block .category img {
      margin-top: 280px;
      height: 170px; }
    .category-block .category .counter {
      width: 36px;
      height: 36px;
      line-height: 36px;
      font-size: 20px;
      color: #ffffff;
      margin: 20px auto;
      background-color: #8e8e8e; }
    .category-block .category .counter-text {
      color: #000000; }
    .category-block .category:hover .h1 {
      color: #00adf1; }
    .category-block .category:hover .hover {
      transform: scale(1);
      opacity: 1; }
      .category-block .category:hover .hover .category-text {
        height: 15.4em;
        overflow: hidden; }
  .category-block .category:last-child {
    border-right: 2px solid #FBFBFB; }
  .category-block .hover {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-image: url("../../images/cat-hover-bg.jpg");
    background-size: cover;
    background-position: center center;
    transition: All 0.5s ease-out;
    transform: scale(0.7);
    opacity: 0; }
    .category-block .hover .category-text {
      margin-top: 275px;
      color: #ffffff;
      font-size: 13px;
      line-height: 1.1em;
      font-weight: 400;
      text-align: center;
      height: 0px;
      padding-left: 10px;
      padding-right: 10px;
      overflow: hidden;
      transition: All 0.5s linear; }
    .category-block .hover .btn-cast {
      margin-top: 20px;
      max-width: 90%;
      transition: All 0.5s linear; }

@media all and (max-width: 991px) {
  .category-block {
    background: transparent;
    height: 1190px; }
    .category-block .category {
      height: 360px; }
      .category-block .category:hover .counter,
      .category-block .category:hover .counter-text {
        display: none; }
      .category-block .category:hover .hover .btn-cast {
        margin-top: 300px; }
      .category-block .category .sm-block .counter {
        padding-top: 250px; }
    .category-block .sm-block {
      position: relative;
      height: 350px;
      background: #8e8e8e; }
      .category-block .sm-block .h1 {
        height: 36px; }
      .category-block .sm-block i {
        margin-top: 50px; }
      .category-block .sm-block img {
        display: none; }
      .category-block .sm-block .counter {
        padding-top: 300px;
        display: inline-block;
        font-size: 15px;
        font-weight: 700;
        text-align: right; }
      .category-block .sm-block .counter-text {
        display: inline-block;
        color: #ffffff;
        font-weight: 700; }
      .category-block .sm-block .hover {
        background-image: none;
        background: rgba(0, 0, 0, 0.4); }
        .category-block .sm-block .hover .btn-cast {
          margin-top: 270px; } }

@media all and (max-width: 767px) {
  .category-block .category .hover {
    display: none; } }

.video-info .container {
  max-width: 764px; }

.video-info .h1 {
  line-height: 50px;
  border-bottom: 1px solid white;
  margin: 100px auto 30px auto;
  position: relative; }
  .video-info .h1 a, .video-info .h1 i {
    position: absolute;
    top: 89px;
    left: -123px;
    color: white;
    z-index: 99;
    cursor: pointer; }
  .video-info .h1:before {
    content: '';
    height: 50px;
    border-bottom: 1px solid white;
    width: 80px;
    display: run-in;
    position: absolute;
    transform: rotate(-33deg);
    transform-origin: 100% 100%;
    margin-left: -80px;
    margin-top: 1px; }

.video-info .video-text {
  margin: 0 auto;
  color: white; }

.video-info .btn-cast {
  margin-top: 25px;
  float: right; }

.header-unit {
  margin-top: 350px;
  min-height: 340px;
  border-right: none;
  border-left: none;
  position: relative; }
  .header-unit .video-info {
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 9; }
    .header-unit .video-info a, .header-unit .video-info i {
      display: inline-block;
      transition: 0.4s; }
      .header-unit .video-info a:after, .header-unit .video-info i:after {
        content: '';
        border-radius: 50%;
        background-color: #00adf1;
        width: 82px;
        height: 82px;
        position: absolute;
        top: 73px;
        left: -145px;
        top: -16px;
        left: -21px;
        z-index: -1; }
      .header-unit .video-info a:hover:after, .header-unit .video-info i:hover:after {
        background: rgba(0, 173, 241, 0.8); }

#video-container {
  position: absolute;
  z-index: -1;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  overflow: hidden; }
  #video-container img {
    height: 100%;
    min-width: 100%; }

video.fillWidth {
  position: absolute;
  width: 1920px;
  top: -335px;
  left: 50%;
  margin-left: -960px;
  z-index: 0; }

@media all and (max-width: 1199px) {
  .video-info .h1 {
    margin-top: 50px; }
    .video-info .h1 i, .video-info .h1:before, .video-info .h1:after {
      display: none; } }

@media all and (max-width: 767px) {
  .header-unit {
    margin-top: 300px; }
    .header-unit .video-info .container {
      text-align: center; }
      .header-unit .video-info .container .h1 {
        margin-top: 25px; }
    .header-unit .video-info .btn-cast {
      float: none; } }

.contact-info {
  height: 200px;
  cursor: pointer; }
  .contact-info > div {
    display: inline-block;
    text-align: center; }
  .contact-info > div:first-child {
    vertical-align: top;
    font-size: 28px;
    color: #e0e0e0; }
    .contact-info > div:first-child i {
      margin-top: 20px; }
  .contact-info > div:last-child {
    width: 80%;
    color: #555555; }
    .contact-info > div:last-child a {
      color: #555555; }
  .contact-info .h2 {
    margin-top: 20px;
    line-height: 22px;
    height: 44px;
    text-align: left; }
  .contact-info .contact-text {
    margin-top: 20px;
    font-size: 14px;
    /* Приближение из-за подстановки шрифтов */
    font-weight: 400;
    line-height: 20px;
    /* Приближение из-за подстановки шрифтов */
    text-align: left; }
  .contact-info:hover > div:first-child, .contact-info.active > div:first-child {
    color: #00adf1; }
  .contact-info:hover > div:last-child, .contact-info.active > div:last-child {
    color: #000000; }
    .contact-info:hover > div:last-child a, .contact-info.active > div:last-child a {
      color: #000000; }

.worldwide {
  margin-top: 50px;
  margin-bottom: 50px; }
  .worldwide .h1 {
    margin-bottom: 30px; }
  .worldwide .contact-info:nth-child(3n) {
    border-right: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0; }
  .worldwide .contact-info:nth-child(5), .worldwide .contact-info:nth-child(6), .worldwide .contact-info:nth-child(7), .worldwide .contact-info:nth-child(11), .worldwide .contact-info:nth-child(12), .worldwide .contact-info:nth-child(13) {
    border-top: 1px solid #e0e0e0; }
  .worldwide .btn-block {
    text-align: center; }
    .worldwide .btn-block .btn-cast {
      margin-top: 30px; }

@media all and (max-width: 1199px) {
  .worldwide .contact-info {
    height: 220px; }
    .worldwide .contact-info:nth-child(2n) {
      border-top: 1px solid transparent;
      border-left: 1px solid transparent;
      border-bottom: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0; }
    .worldwide .contact-info:nth-child(2n+1) {
      border-top: 1px solid transparent;
      border-left: 1px solid transparent;
      border-bottom: 1px solid #e0e0e0;
      border-right: 1px solid transparent; }
    .worldwide .contact-info:nth-last-child(2), .worldwide .contact-info:nth-last-child(3), .worldwide .contact-info:nth-child(7) {
      border-bottom: 1px solid transparent; }
    .worldwide .contact-info:nth-child(7) {
      border-top: 1px solid #e0e0e0; } }

@media all and (max-width: 767px) {
  .worldwide {
    margin-top: 30px; }
    .worldwide .h1 {
      margin-bottom: 15px; }
    .worldwide .contact-info:nth-last-child(n+2) {
      border-top: 1px solid transparent;
      border-left: 1px solid transparent;
      border-bottom: 1px solid #e0e0e0;
      border-right: 1px solid transparent; } }

@media all and (max-width: 480px) {
  .worldwide .contact-info {
    height: 250px;
    overflow: hidden; } }

/* ----------------------------------------------------------------------------------------------------------
---------------------------------Home page End-------------------------------------------------------
---------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------------------
---------------------------------About page -------------------------------------------------------
---------------------------------------------------------------------------------------------------------- */
.leadership {
  background: #EDEDED; }

.leadership,
.history {
  background: #EDEDED; }
  .leadership .video-link,
  .history .video-link {
    position: relative; }
    .leadership .video-link .video-info .container,
    .history .video-link .video-info .container {
      max-width: 650px;
      position: absolute;
      top: 0;
      right: 0; }
    .leadership .video-link .video-info .h1,
    .history .video-link .video-info .h1 {
      margin: 70px auto 10px auto;
      line-height: 36px;
      height: 72px;
      border-color: #00adf1; }
      .leadership .video-link .video-info .h1 a,
      .history .video-link .video-info .h1 a {
        position: absolute;
        top: 87px;
        left: -145px;
        color: white;
        z-index: 99;
        width: 82px;
        height: 82px;
        border-radius: 82px;
        background: rgba(255, 255, 255, 0.6); }
        .leadership .video-link .video-info .h1 a:hover i,
        .history .video-link .video-info .h1 a:hover i {
          color: rgba(0, 0, 0, 0.6); }
      .leadership .video-link .video-info .h1 i,
      .history .video-link .video-info .h1 i {
        transition: 0.4s;
        top: -18px;
        left: -21px;
        margin-top: 50%;
        margin-left: 50%;
        color: #00adf1; }
      .leadership .video-link .video-info .h1 i.play,
      .history .video-link .video-info .h1 i.play {
        top: -17px;
        left: -18px; }
      .leadership .video-link .video-info .h1:before,
      .history .video-link .video-info .h1:before {
        margin-left: -79px;
        margin-top: 22px;
        color: #00adf1;
        border-color: #00adf1; }
      .leadership .video-link .video-info .h1:after,
      .history .video-link .video-info .h1:after {
        top: 88px;
        color: #00adf1; }
    .leadership .video-link .video-info .video-text,
    .history .video-link .video-info .video-text {
      color: #00adf1; }
  .leadership .president,
  .history .president {
    background: #ffffff;
    padding-bottom: 30px; }
  .leadership .h1,
  .history .h1 {
    margin-top: 20px;
    line-height: 60px;
    height: 60px;
    border-bottom: 1px solid #e0e0e0; }
  .leadership .text,
  .history .text {
    padding-top: 25px;
    padding-bottom: 25px; }
    .leadership .text .btn-cast,
    .history .text .btn-cast {
      max-width: 360px;
      margin-bottom: 25px; }
  .leadership .awards,
  .history .awards {
    line-height: 30px; }
  .leadership img,
  .history img {
    padding-top: 25px; }

.service .content.info {
  margin-top: 0px; }
  .service .content.info .gallery-wrap {
    width: 300px; }
  .service .content.info .text-one {
    width: -webkit-calc(100% - 340px);
    width: calc(100% - 340px); }
  @media all and (max-width: 480px) {
    .service .content.info .gallery-wrap,
    .service .content.info .text-one {
      width: 100%; } }

/* ----------------------------------------------------------------------------------------------------------
---------------------------------About End -------------------------------------------------------
---------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------------------
--------------------------------- Products -------------------------------------------------------
---------------------------------------------------------------------------------------------------------- */
.products .category-block {
  height: 320px;
  overflow: hidden;
  background: #efefef; }
  .products .category-block .category {
    height: 320px;
    position: relative;
    background-size: cover;
    background-position: center center; }
    .products .category-block .category .h1,
    .products .category-block .category .h2 {
      color: #1f1f1f; }
    .products .category-block .category img,
    .products .category-block .category .background-color {
      position: absolute;
      padding: 0;
      margin: 0;
      top: 0;
      left: 0;
      width: 100%;
      max-width: auto; }
    .products .category-block .category .background-color {
      height: 320px;
      background: rgba(239, 239, 239, 0.8); }
    .products .category-block .category i {
      margin-bottom: 15px; }
    .products .category-block .category .counter-text {
      font-size: 15px; }
    .products .category-block .category .counter,
    .products .category-block .category .counter-text {
      display: inline-block;
      margin-top: 0; }
    .products .category-block .category .btn-cast {
      display: none; }
    .products .category-block .category:hover .h1,
    .products .category-block .category:hover .h2,
    .products .category-block .category:hover .counter-text {
      color: #ffffff; }

.products .head-text {
  margin-top: 25px;
  margin-bottom: 25px; }
  .products .head-text .h1 {
    margin-bottom: 15px; }

.products .items #cargo .column {
  padding-left: 15px; }

.products .items .category-name,
.products .items .subcategory-name,
.products .items .item-name {
  font-size: 30px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 10px; }

.products .items .category-name,
.products .items .subcategory-name {
  height: 58px;
  line-height: 58px; }

.products .items .category-name {
  color: #ffffff;
  background: #8D8D8D;
  width: 100%;
  font-weight: 700; }
  .products .items .category-name span {
    font-weight: 400; }

.products .items .subcategory-name {
  background: #F5F5F5; }

.products .items .item-name {
  font-size: 18px;
  color: #555555;
  height: 35px;
  line-height: 35px;
  margin-bottom: 0;
  border-bottom: 1px solid #e0e0e0;
  position: relative; }
  .products .items .item-name .plus {
    position: absolute;
    right: 20px; }
  .products .items .item-name .triangle {
    position: absolute;
    top: -2px;
    left: -5px;
    font-size: 30px;
    color: white;
    opacity: 0;
    width: 0;
    overflow: hidden;
    transition: 0.4s; }

.products .items .item-link {
  height: 50px;
  padding-left: 20px;
  padding-right: 20px; }
  .products .items .item-link a {
    display: inline-block;
    padding: 10px;
    color: #00adf1;
    text-decoration: underline; }

.products .items dt.active .item-name {
  background: #00adf1;
  color: #ffffff; }
  .products .items dt.active .item-name .triangle {
    width: 15px;
    opacity: 1; }

.products .items .show-item-text {
  padding-bottom: 20px; }

@media all and (max-width: 991px) {
  .products .category-block {
    background: transparent;
    height: 1290px; }
    .products .category-block .category {
      height: 320px; }
      .products .category-block .category:hover .btn-cast {
        display: none; }
    .products .category-block .sm-block {
      position: relative;
      height: 400px;
      background-color: transparent; }
      .products .category-block .sm-block img {
        display: none; }
      .products .category-block .sm-block .counter {
        padding-top: 0;
        display: inline-block;
        font-size: 15px;
        font-weight: 700;
        text-align: center; }
      .products .category-block .sm-block .counter-text {
        display: inline-block;
        color: #1f1f1f;
        font-weight: 700; }
      .products .category-block .sm-block .hover {
        background-image: none;
        background: rgba(0, 0, 0, 0.4); } }

.category-menu {
  background: #8e8e8e; }
  .category-menu .category {
    padding: 0; }
  .category-menu .category:not(:last-child) {
    border-right: 2px solid #ffffff; }
  .category-menu a {
    padding: 20px 10px;
    width: 100%;
    text-align: center;
    display: inline-block;
    transition: 0.4s; }
    .category-menu a:hover, .category-menu a.active {
      background: #00adf1; }
  .category-menu .h1 {
    color: #ffffff;
    font-size: 27px; }
  .category-menu .h3 {
    color: #ffffff; }
  @media all and (max-width: 1199px) {
    .category-menu .h1 {
      height: 2.2em;
      line-height: 1.1em; } }
  @media all and (max-width: 767px) {
    .category-menu .category:not(:last-child) {
      border-right: none;
      border-bottom: 2px solid #ffffff; }
    .category-menu .h1 {
      height: 1.4em; } }

.product-category .category-info {
  background-size: cover;
  background-position: center center;
  padding: 50px 0;
  background-color: #000000;
  position: relative; }
  .product-category .category-info .h1,
  .product-category .category-info .text {
    margin-bottom: 15px; }
  .product-category .category-info .text,
  .product-category .category-info .counter,
  .product-category .category-info .counter-text {
    color: #ffffff; }
  .product-category .category-info .counter,
  .product-category .category-info .counter-text {
    display: inline-block;
    height: 36px;
    line-height: 36px; }
  .product-category .category-info .counter {
    background: #8e8e8e;
    text-align: center;
    width: 36px;
    margin-right: 10px; }

.product-category .subcategory-name {
  height: 45px;
  line-height: 45px;
  background: #8e8e8e;
  font-size: 30px;
  color: #ffffff; }

.product-category .subcategoty-photo {
  background: #F5F5F5; }

.product-category .item-links {
  padding-right: 0; }
  .product-category .item-links a {
    display: inline-block;
    width: 100%;
    padding: 15px;
    transition: 0.4s;
    border-bottom: 1px solid #e0e0e0; }
    .product-category .item-links a div:first-child {
      color: #00adf1;
      font-size: 18px;
      font-weight: 700;
      transition: 0.4s; }
    .product-category .item-links a div:last-child {
      color: #000000; }
    .product-category .item-links a:hover {
      background: #F5F5F5; }
      .product-category .item-links a:hover div:first-child {
        color: #000000; }

@media all and (max-width: 767px) {
  .product-category {
    text-align: center; }
    .product-category .counter {
      margin-right: 0; }
    .product-category .item-links {
      margin-right: 15px; } }

.product-one-item .btn-block,
.product-category .btn-block,
.dealer-locator .btn-block {
  margin-top: 30px;
  margin-bottom: 30px; }
  .product-one-item .btn-block a,
  .product-category .btn-block a,
  .dealer-locator .btn-block a {
    color: #000000; }
    .product-one-item .btn-block a i,
    .product-category .btn-block a i,
    .dealer-locator .btn-block a i {
      display: inline-block;
      color: #00adf1;
      font-size: 24px;
      padding-right: 10px;
      padding-left: 10px; }
    .product-one-item .btn-block a:nth-child(2),
    .product-category .btn-block a:nth-child(2),
    .dealer-locator .btn-block a:nth-child(2) {
      float: right; }
    .product-one-item .btn-block a:hover i,
    .product-category .btn-block a:hover i,
    .dealer-locator .btn-block a:hover i {
      -webkit-animation: button-togle 1s;
      -o-animation: button-togle 1s;
      animation: button-togle 1s; }

.product-one-item .content-block {
  position: relative; }
  .product-one-item .content-block .grey-block {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background: #EDEDED; }

.product-one-item .left-block,
.product-one-item .right-block {
  padding: 60px 20px; }

.product-one-item .h1.sub {
  font-weight: 400;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  margin-bottom: 10px; }

.product-one-item .item-watch {
  color: #000000;
  font-size: 12px; }
  .product-one-item .item-watch i {
    height: 20px;
    width: 20px;
    background: #00adf1;
    border-radius: 50%;
    line-height: 18px;
    padding-left: 2px;
    color: #ffffff;
    margin-right: 15px;
    border: 1px solid #00adf1;
    transition: 0.5s; }
  .product-one-item .item-watch:hover i {
    transform: rotate(360deg);
    background: #ffffff;
    color: #00adf1; }

.product-one-item .gallery-wrap {
  margin-top: 30px;
  width: 100% !important;
  text-align: center; }
  .product-one-item .gallery-wrap a.effect-ruby {
    background: transparent;
    max-width: 100%;
    transition: 0.4s; }
    .product-one-item .gallery-wrap a.effect-ruby img {
      max-width: 100%; }
    .product-one-item .gallery-wrap a.effect-ruby p {
      width: 150px;
      margin-top: -20px;
      margin-left: -75px; }
    .product-one-item .gallery-wrap a.effect-ruby:hover {
      background: #00adf1; }

.product-one-item .right-block {
  background-color: #EDEDED; }
  .product-one-item .right-block .h1 {
    color: #000000;
    margin-bottom: 20px; }
  .product-one-item .right-block .text-two,
  .product-one-item .right-block .text-one {
    margin-bottom: 25px;
    font-size: 13px;
    line-height: 20 pxs; }
  .product-one-item .right-block .text-two table {
    margin-left: 0 !important; }
    .product-one-item .right-block .text-two table tr td {
      font-size: 13px;
      line-height: 20px; }
    .product-one-item .right-block .text-two table tr td:first-child {
      text-align: left !important;
      color: #8e8e8e !important; }
  .product-one-item .right-block .text-one ul {
    text-decoration: none; }
  .product-one-item .right-block .text-one p {
    line-height: 20px; }
  .product-one-item .right-block .btn-cast {
    padding-left: 80px;
    padding-right: 80px;
    margin-left: 80px; }

@media all and (max-width: 991px) {
  .product-one-item .right-block {
    text-align: center; }
    .product-one-item .right-block .text-one {
      text-align: left; }
    .product-one-item .right-block .btn-cast {
      margin: 30px auto;
      padding-right: 30px;
      padding-left: 30px; } }

/* ----------------------------------------------------------------------------------------------------------
--------------------------------- Products End -------------------------------------------------------
---------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------------------
--------------------------------- Media $ EVENTS -------------------------------------------------------
---------------------------------------------------------------------------------------------------------- */
.media .sub-nav {
  height: 320px;
  overflow: hidden;
  background-size: cover;
  background-position: center center; }
  .media .sub-nav .sub-nav-block .sub-nav-block-inner {
    width: 320px;
    height: 320px;
    background: rgba(0, 0, 0, 0.6); }
    .media .sub-nav .sub-nav-block .sub-nav-block-inner ul {
      padding: 15px; }
      .media .sub-nav .sub-nav-block .sub-nav-block-inner ul li {
        height: 44px;
        border-bottom: 1px solid #00adf1;
        transition: 0.3s; }
        .media .sub-nav .sub-nav-block .sub-nav-block-inner ul li a {
          width: 100%;
          display: inline-block;
          height: 44px;
          line-height: 44px;
          padding-left: 15px; }
        .media .sub-nav .sub-nav-block .sub-nav-block-inner ul li:hover, .media .sub-nav .sub-nav-block .sub-nav-block-inner ul li.active {
          background: #00adf1; }
          .media .sub-nav .sub-nav-block .sub-nav-block-inner ul li:hover a, .media .sub-nav .sub-nav-block .sub-nav-block-inner ul li.active a {
            color: #ffffff; }

.media .content {
  padding-top: 20px;
  padding-bottom: 50px; }
  .media .content .h1 {
    line-height: 60px;
    height: 60px; }
  .media .content .media-text {
    padding-top: 25px;
    border-top: 1px solid #e0e0e0; }
  .media .content .item:nth-child(2) {
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-bottom: 1px solid #e0e0e0;
    border-right: 1px solid transparent; }
  .media .content .item:first-child {
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-bottom: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0; }
  .media .content .item .header {
    color: #555555;
    font-size: 22px;
    line-height: 1.2em;
    height: 2.4em;
    font-weight: 700;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 20px;
    overflow: hidden; }
  .media .content .item .right-block {
    height: 230px; }
    .media .content .item .right-block .item-text {
      line-height: 1.2em;
      height: 7.2em;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; }
    .media .content .item .right-block .btn-cast {
      margin-top: 40px; }
  .media .content .item:hover img, .media .content .item img.active {
    filter: grayscale(0%);
    filter: none; }
  .media .content .item img {
    filter: grayscale(100%);
    filter: gray;
    /* IE 6-9 */
    transition: 0.5s ease-out;
    padding: 4px 2px; }

.news-one-item .date-one {
  color: #555555;
  font-size: 11px;
  font-weight: 400;
  text-align: left; }

.news-one-item .content {
  margin-top: 20px;
  border-top: 1px solid #e0e0e0;
  padding-top: 25px; }

.news-one-item .text-one {
  display: inline-block;
  width: -webkit-calc(100% - 220px);
  width: calc(100% - 220px);
  vertical-align: top;
  margin-left: 20px; }

.news-one-item .btn-block a {
  color: #000000; }
  .news-one-item .btn-block a i {
    display: inline-block;
    color: #00adf1;
    font-size: 24px;
    padding-right: 10px;
    padding-left: 10px; }
  .news-one-item .btn-block a:last-child {
    float: right; }
  .news-one-item .btn-block a:hover i {
    -webkit-animation: button-togle 1s;
    -o-animation: button-togle 1s;
    animation: button-togle 1s; }

.success-stories .h1 {
  margin-bottom: 0; }

.success-stories .story {
  margin-top: 30px;
  border-bottom: 1px solid #e0e0e0; }
  .success-stories .story .img-block {
    height: 210px; }
  .success-stories .story .name,
  .success-stories .story p {
    margin-bottom: 20px; }
  .success-stories .story .name {
    color: #555555;
    font-size: 22px;
    font-weight: 700; }

.success-paginator,
.news-paginator {
  margin-top: 30px; }
  .success-paginator .button-all,
  .news-paginator .button-all {
    color: #00adf1;
    float: right;
    border-bottom: 1px solid #00adf1; }

.show-item, .header-table {
  height: 40px; }
  .show-item div, .header-table div {
    height: 40px;
    line-height: 40px; }

.header-table {
  background: rgba(142, 142, 142, 0.5); }
  .header-table div {
    color: #ffffff;
    font-weight: 700; }

.show-item {
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  transition: 0.3s; }
  .show-item .show-date {
    color: #00adf1; }
  .show-item:hover, .show-item.active {
    background: #00adf1; }
    .show-item:hover .show-date, .show-item.active .show-date {
      color: #ffffff; }
  .show-item i {
    font-size: 28px;
    color: #e0e0e0;
    transition: 0.3s; }
    .show-item i:hover {
      color: #ffffff; }

.media .show-item-text > div {
  margin-bottom: 50px; }

.show-item-text {
  margin-top: 15px;
  margin-bottom: 40px; }

dl,
dt {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0; }

dt {
  cursor: pointer; }

dd {
  overflow: hidden;
  position: relative;
  transition: height 0.8s ease 0s;
  height: 0px; }
  dd table tr td:first-child {
    min-width: 160px;
    white-space: nowrap; }

@media all and (max-width: 991px) {
  .media .content .item .header {
    font-size: 18px; }
  .media .content .item .img-block,
  .media .content .item .right-block {
    height: 200px; }
    .media .content .item .img-block .btn-cast,
    .media .content .item .right-block .btn-cast {
      margin-top: 10px; }
  .show-item {
    height: 160px; }
    .show-item .name {
      line-height: 20px; } }

@media all and (max-width: 767px) {
  .media .sub-nav .sub-nav-block .sub-nav-block-inner {
    width: 100%; }
    .media .sub-nav .sub-nav-block .sub-nav-block-inner ul {
      padding: 0; }
  .media .content .h1 {
    margin-bottom: 15px; }
  .news-one-item .gallery-wrap,
  .news-one-item .text-one {
    display: block;
    margin: 0 auto; }
  .news-one-item .text-one {
    width: 100%; } }

/* ----------------------------------------------------------------------------------------------------------
--------------------------------- Media $ EVENTS END -------------------------------------------------------
---------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------------------
--------------------------------- Dealer Locator page-------------------------------------------------------
---------------------------------------------------------------------------------------------------------- */
.dealer-locator {
  padding-bottom: 50px; }
  .dealer-locator .button-block {
    margin-top: 50px; }
  .dealer-locator .h1 {
    padding-bottom: 25px;
    border-bottom: 1px solid #e0e0e0; }
  .dealer-locator .h2 {
    padding: 15px 0; }
  .dealer-locator .text {
    padding-bottom: 40px; }
  .dealer-locator .check {
    line-height: 43px;
    height: 43px; }
  .dealer-locator .contact-info {
    border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0; }
  .dealer-locator .btn-block {
    text-align: left; }

/* ----------------------------------------------------------------------------------------------------------
--------------------------------- Dealer Locator End-------------------------------------------------------
---------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------------------
--------------------------------- Contact page-------------------------------------------------------
---------------------------------------------------------------------------------------------------------- */
#location {
  height: 350px; }

.contact textarea {
  height: 102px; }

.contact .btn-cast {
  float: right; }

.contact .h1 {
  padding-bottom: 20px; }

.success {
  text-align: left; }
  .success .h1 {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 20px; }
  .success .btn-cast {
    float: left;
    margin-top: 20px; }

/* ----------------------------------------------------------------------------------------------------------
--------------------------------- Contact page End-------------------------------------------------------
---------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------------------
--------------------------------- Footer -------------------------------------------------------
---------------------------------------------------------------------------------------------------------- */
footer {
  background: #1f1f1f; }
  footer .footer-nav-inner {
    padding-top: 20px;
    padding-bottom: 80px; }
    footer .footer-nav-inner > div {
      margin-top: 20px; }
  footer .bottom-block {
    color: #ffffff; }
    footer .bottom-block > div {
      line-height: 60px; }
    footer .bottom-block .dealer-dashbord {
      background: #00adf1;
      height: 60px;
      display: inline-block;
      line-height: 60px;
      padding-left: 20px;
      padding-right: 20px;
      font-weight: 700;
      transition: 0.4s linear; }
      footer .bottom-block .dealer-dashbord a {
        font-weight: 700; }
      footer .bottom-block .dealer-dashbord:hover {
        background: rgba(0, 173, 241, 0.8);
        color: #000000; }
  footer .social {
    display: inline-block;
    color: white;
    height: 60px;
    line-height: 60px;
    font-weight: 700; }
    footer .social .round-soc {
      display: inline-block;
      height: 30px;
      width: 30px;
      text-align: center;
      line-height: 30px;
      border-radius: 50%;
      background: #ffffff;
      vertical-align: middle;
      margin-left: 5px;
      margin-right: 5px; }
      footer .social .round-soc i {
        color: #1f1f1f; }
      footer .social .round-soc:hover i {
        color: #00adf1;
        font-size: 20px; }
  footer .ukie {
    display: inline-block;
    line-height: 60px;
    height: 60px; }
    footer .ukie i {
      vertical-align: sub; }
    footer .ukie:hover i.ico-ukie {
      background-position: 0 -550px; }

.footer-nav .header {
  color: #00adf1;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px; }

.footer-nav ul {
  list-style: none;
  color: #ffffff;
  font-size: 17px;
  font-weight: 400;
  line-height: 30px;
  text-align: left; }

@media all and (max-width: 767px) {
  .footer-nav {
    text-align: center; }
    .footer-nav ul {
      text-align: center; }
  .bottom-block > div {
    text-align: center; } }

/* ----------------------------------------------------------------------------------------------------------
--------------------------------- Footer End -------------------------------------------------------
---------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------------------
--------------------------------- Errors -------------------------------------------------------
---------------------------------------------------------------------------------------------------------- */
.error input:not([type="checkbox"]):not([type="submit"]), .error textarea {
  background-color: #F6DAD9;
  border-color: #E0ADAC; }

.error ul {
  color: rgba(255, 0, 0, 0.7); }

.error-container {
  min-height: -webkit-calc(100% - 478px);
  min-height: calc(100% - 478px);
  background-image: url("../../images/error-bg.png");
  box-shadow: inset 100px 41px 120px #FBFBFB, inset -100px -41px 120px 0px #FBFBFB;
  text-align: center; }
  .error-container .header-line {
    margin-top: 30px;
    font-size: 118px;
    font-weight: 700;
    letter-spacing: 2.36px;
    color: #00adf1; }
  .error-container .subheader {
    font-size: 22px; }
  .error-container .text {
    max-width: 600px;
    margin: 20px auto; }
  .error-container .btn-cast {
    min-width: 340px;
    margin: 20px; }
  .error-container .error-btn-block {
    font-size: 22px; }
  @media all and (max-width: 767px) {
    .error-container .btn-cast {
      min-width: 260px;
      max-width: 260px;
      margin: 20px 0; } }

.slick-arrow:after {
  content: '';
  width: 10px;
  height: 10px;
  background-color: #00adf1;
  position: absolute;
  top: 15px;
  transform: rotate(45deg); }

.slick-arrow.slick-prev:after {
  left: 0; }

.slick-arrow.slick-prev:before {
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  opacity: 0.7; }

.slick-arrow.slick-next:after {
  right: 0; }

.slick-arrow.slick-next:before {
  border-right: 2px solid white;
  border-top: 2px solid white;
  opacity: 0.7; }

.promo__attachment {
  display: inline-block;
  margin-bottom: 10px;
  color: #000000;
  transition: 0.3s; }
  .promo__attachment:before {
    color: #00adf1; }
  .promo__attachment:hover {
    opacity: 0.8; }

.l-promo img {
  max-width: 100%; }

.l-promo .sub-nav {
  background-image: url("/resources/images/yl6j64.jpg"); }

.l-promo iframe, .l-promo object, .l-promo embed {
  max-width: 100%; }

.l-promo .promo__attachments {
  margin-top: 25px; }

/* ================= New Style ===================== */
@media (max-width: 1199px) {
  .leadership .video-link .video-info .h1 a,
  .history .video-link .video-info .h1 a {
    z-index: -1; } }

@media (max-width: 479px) {
  .products .items .category-name {
    font-size: 24px; } }

@media (max-width: 500px) {
  .products .items .subcategory-name {
    height: auto;
    line-height: 30px; } }

@media (max-width: 500px) {
  .products .items .item-name {
    font-size: 16px; } }

.ico-english {
  background-image: url(/resources/images/flag-us.png);
  background-position: 0 0;
  height: 57px;
  width: 34px; }

.ico-english-min {
  background-image: url(/resources/images/flag-us-min.png);
  background-position: 0 0;
  height: 22px;
  width: 57px; }

.ico-german-new {
  background-image: url(/resources/images/de-new.png);
  background-position: 0 0;
  height: 57px;
  width: 34px; }

.ico-german-new-min {
  background-image: url(/resources/images/de-new-min.png);
  background-position: 0 0;
  height: 22px;
  width: 57px; }

.ico-japan-new {
  background-image: url(/resources/images/jp-new.png);
  background-position: 0 0;
  height: 57px;
  width: 34px; }

@media (max-width: 1199px) {
  .header-unit .video-info a.video-btn {
    display: none; } }

.video-home-link {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px; }
  @media (min-width: 1200px) {
    .video-home-link {
      display: none !important; } }

.icon-carleaseplay.active {
  position: relative;
  display: block;
  font-size: 24px; }
  .icon-carleaseplay.active:after {
    width: 60px !important;
    height: 60px !important; }
  .icon-carleaseplay.active:before {
    margin-left: 0; }
